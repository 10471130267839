import React, { useState, useEffect, useRef, useCallback } from 'react';

// Root Component
function LandingPrem() {
  const [scrolled, setScrolled] = useState(false);
  const [currentSection, setCurrentSection] = useState('hero');
  const [isLowPerformanceDevice, setIsLowPerformanceDevice] = useState(false);
  const [colorProgress, setColorProgress] = useState(0); // Track color transition progress (0-1)
  
  // Monochromatic colors (starting state)
  const monochromeColors = {
    background: '#0a0a0a',      // Near-black background
    backgroundDarker: '#000000', // Pure black background
    backgroundLighter: '#1a1a1a', // Slightly lighter background
    foreground: '#ffffff',      // Pure white for maximum contrast
    comment: '#777777',         // Medium gray for less important text
    selection: '#333333',       // Dark gray for selections
    magenta: '#e6e6e6',         // Light gray (was magenta)
    blue: '#cccccc',            // Medium-light gray (was blue)
    cyan: '#aaaaaa',            // Medium gray (was cyan)
    green: '#dddddd',           // Light gray (was green)
    yellow: '#bbbbbb',          // Medium-light gray (was yellow)
    orange: '#999999',          // Medium-dark gray (was orange)
    red: '#eeeeee',             // Near-white (was red)
  };
  
  // Colorful colors (end state)
  const colorfulColors = {
    background: '#0a0a0a',      // Keep dark background
    backgroundDarker: '#000000', // Keep dark background
    backgroundLighter: '#1a1a1a', // Keep dark background
    foreground: '#ffffff',      // Keep white text
    comment: '#a0a0c0',         // Slightly blue-tinted gray for text
    selection: '#333344',       // Slightly blue-tinted selection
    magenta: '#e459e4',         // Vibrant magenta
    blue: '#5995ff',            // Vibrant blue
    cyan: '#64d9d9',            // Vibrant cyan
    green: '#7ae67a',           // Vibrant green
    yellow: '#ffcf57',          // Vibrant yellow
    orange: '#ff9447',          // Vibrant orange
    red: '#ff6b6b',             // Vibrant red
  };
  
  // Interpolate between two colors based on progress (0-1)
  const interpolateColor = (color1, color2, progress) => {
    // Parse hex colors to RGB
    const parseHex = (hex) => {
      const r = parseInt(hex.slice(1, 3), 16);
      const g = parseInt(hex.slice(3, 5), 16);
      const b = parseInt(hex.slice(5, 7), 16);
      return [r, g, b];
    };
    
    // Convert RGB to hex
    const toHex = (rgb) => {
      return `#${rgb.map(c => Math.round(c).toString(16).padStart(2, '0')).join('')}`;
    };
    
    const rgb1 = parseHex(color1);
    const rgb2 = parseHex(color2);
    
    // Interpolate each RGB component
    const interpolatedRgb = rgb1.map((c1, i) => {
      const c2 = rgb2[i];
      return c1 + (c2 - c1) * progress;
    });
    
    return toHex(interpolatedRgb);
  };
  
  // Calculate current color scheme based on scroll progress
  const getCurrentColors = () => {
    const result = {};
    
    // For each color, interpolate between monochrome and colorful
    Object.keys(monochromeColors).forEach(key => {
      result[key] = interpolateColor(
        monochromeColors[key],
        colorfulColors[key],
        colorProgress
      );
    });
    
    return result;
  };
  
  // Get current interpolated colors
  const colors = getCurrentColors();

  // Make all sections and story elements visible on mobile/low performance devices
  useEffect(() => {
    if (isLowPerformanceDevice) {
      // Add in-view class to all sections to ensure visibility
      document.querySelectorAll('.scroll-section').forEach(section => {
        section.classList.add('in-view');
        section.style.opacity = '1';
        section.style.transform = 'translateY(0)';
      });
      
      // Make all story elements visible
      document.querySelectorAll('.story-element').forEach(el => {
        el.style.opacity = '1';
        el.style.transform = 'translateY(0)';
      });
    }
  }, [isLowPerformanceDevice]);

  // Apply theme colors as CSS variables
  useEffect(() => {
    const root = document.documentElement;
    Object.entries(colors).forEach(([key, value]) => {
      root.style.setProperty(`--color-${key}`, value);
      root.style.setProperty(`--color-mmagenta`, monochromeColors.magenta);
      root.style.setProperty(`--color-mblue`, monochromeColors.blue);
    });
    
    // Apply performance optimizations
    document.querySelectorAll('.scroll-section').forEach(section => {
      section.style.willChange = 'transform, opacity';
    });
    
    // Ensure story elements are visible by default
    document.querySelectorAll('.story-element').forEach(el => {
      el.style.opacity = '1';
      el.style.transform = 'translateY(0)';
    });
    
    document.querySelectorAll('.animate-on-scroll').forEach(el => {
      el.style.willChange = 'transform, opacity';
    });
    
    // Force hardware acceleration where needed
    const forceAcceleration = () => {
      document.body.style.transform = 'translateZ(0)';
      
      // Clean up after initial rendering
      setTimeout(() => {
        document.querySelectorAll('.animate-on-scroll').forEach(el => {
          if (!el.classList.contains('in-view')) {
            el.style.willChange = 'auto';
          }
        });
      }, 1000);
    };
    
    forceAcceleration();
    
    return () => {
      // Clean up will-change properties
      document.querySelectorAll('.scroll-section, .animate-on-scroll').forEach(el => {
        el.style.willChange = 'auto';
      });
    };
  }, [colors]);

  // Detect low performance devices
  useEffect(() => {
    const checkPerformance = () => {
      const isMobile = window.innerWidth < 768;
      const isLowEnd = navigator.hardwareConcurrency ? navigator.hardwareConcurrency <= 4 : false;
      setIsLowPerformanceDevice(isMobile || isLowEnd);
    };
    
    checkPerformance();
    window.addEventListener('resize', checkPerformance);
    
    return () => window.removeEventListener('resize', checkPerformance);
  }, []);

  // Initialize GSAP ScrollTrigger for more engaging scroll animations
  useEffect(() => {
    // This would typically use GSAP, but we'll use a simplified approach
    // that works without external dependencies
    if (!isLowPerformanceDevice) {
      const sections = document.querySelectorAll('.scroll-section');
      
      let ticking = false;
      
      const handleScroll = () => {
        if (!ticking) {
          requestAnimationFrame(() => {
            const scrollY = window.scrollY;
            
            sections.forEach(section => {
              const rect = section.getBoundingClientRect();
              const isInView = rect.top < window.innerHeight * 0.75 && rect.bottom > 0;
              
              if (isInView) {
                section.classList.add('in-view');
              }
            });
            
            ticking = false;
          });
          
          ticking = true;
        }
      };
      
      window.addEventListener('scroll', handleScroll, { passive: true });
      handleScroll(); // Initial check
      
      return () => window.removeEventListener('scroll', handleScroll);
    }
  }, [isLowPerformanceDevice]);

  // Enhanced section detection with improved threshold and color transition
  useEffect(() => {
    let ticking = false;
    let scrollTimeout;
    
    const handleScroll = () => {
      if (!ticking) {
        window.requestAnimationFrame(() => {
          const scrollY = window.scrollY;
          const windowHeight = window.innerHeight;
          const documentHeight = Math.max(
            document.body.scrollHeight,
            document.body.offsetHeight,
            document.documentElement.clientHeight,
            document.documentElement.scrollHeight,
            document.documentElement.offsetHeight
          );
          
          // Calculate scroll progress for color transition (0 to 1)
          // Start revealing color after 15% of scroll and complete by 70%
          const viewableHeight = documentHeight - windowHeight;
          let newColorProgress = 0;
          
          if (viewableHeight > 0) {
            const scrollPercent = scrollY / viewableHeight;
            // Adjust these values to control when color starts/completes
            const colorStartThreshold = 0.15;
            const colorEndThreshold = 0.7;
            
            if (scrollPercent <= colorStartThreshold) {
              newColorProgress = 0;
            } else if (scrollPercent >= colorEndThreshold) {
              newColorProgress = 1;
            } else {
              // Normalize progress between start and end thresholds
              newColorProgress = (scrollPercent - colorStartThreshold) / 
                                (colorEndThreshold - colorStartThreshold);
            }
          }
          
          // Update color progress state
          setColorProgress(newColorProgress);
          
          // Check if page has been scrolled enough to change header appearance
          setScrolled(scrollY > 50);
          
          // Improved section detection with better thresholds
          const sections = document.querySelectorAll('section[id]');
          
          // Default to hero if at the very top
          if (scrollY < 100) {
            setCurrentSection('hero');
          } else {
            sections.forEach(section => {
              const sectionTop = section.offsetTop;
              const sectionHeight = section.offsetHeight;
              
              // More precise section detection logic
              // Using 40% threshold for detecting when a section is current
              if (
                scrollY >= sectionTop - windowHeight * 0.4 &&
                scrollY < sectionTop + sectionHeight - windowHeight * 0.4
              ) {
                setCurrentSection(section.id);
              }
            });
          }
          
          ticking = false;
        });
        
        ticking = true;
      }
      
      // Debounce section detection for performance
      clearTimeout(scrollTimeout);
      scrollTimeout = setTimeout(() => {
        // Do an extra check when scrolling stops
        const scrollY = window.scrollY;
        const windowHeight = window.innerHeight;
        const sections = document.querySelectorAll('section[id]');
        
        // Default to hero if at the very top of the page
        if (scrollY < 100) {
          setCurrentSection('hero');
        } else {
          sections.forEach(section => {
            const sectionTop = section.offsetTop;
            const sectionHeight = section.offsetHeight;
            
            if (
              scrollY >= sectionTop - windowHeight * 0.4 &&
              scrollY < sectionTop + sectionHeight - windowHeight * 0.4
            ) {
              setCurrentSection(section.id);
            }
          });
        }
      }, 100);
    };
    
    window.addEventListener('scroll', handleScroll, { passive: true });
    handleScroll(); // Initial check when component mounts
    
    return () => {
      window.removeEventListener('scroll', handleScroll);
      clearTimeout(scrollTimeout);
    };
  }, []);

  return (
    <div className="relative overflow-x-hidden w-full bg-[var(--color-background)] text-[var(--color-foreground)]">
      <style jsx global>{`
        /* Color transition indicator */
        body::after {
          content: '';
          position: fixed;
          top: 10px;
          right: 10px;
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background: linear-gradient(135deg, var(--color-magenta), var(--color-blue));
          opacity: ${colorProgress};
          transition: opacity 0.3s ease-out;
          z-index: 9999;
          pointer-events: none;
        }
      
        .story-element {
          opacity: 1 !important;
          transform: translateY(0) !important;
          will-change: transform, opacity;
          transition: opacity 0.8s ease-out, transform 0.8s ease-out, color 0.5s ease-out, background-color 0.5s ease-out;
        }
        
        @keyframes fadeInUp {
          from {
            opacity: 0;
            transform: translateY(20px);
          }
          to {
            opacity: 1;
            transform: translateY(0);
          }
        }
        
        #hero .story-element {
          animation: fadeInUp 0.8s forwards;
          animation-delay: calc(var(--delay, 0s));
        }
        
        /* Ensure visibility of hero section elements */
        #hero .hero-title,
        #hero .story-card,
        #hero button {
          opacity: 1 !important;
          visibility: visible !important;
          transform: translateY(0) !important;
        }
        
        /* Color transition animations */
        .color-transition {
          transition: color 0.5s ease-out, 
                      background-color 0.5s ease-out, 
                      border-color 0.5s ease-out, 
                      fill 0.5s ease-out,
                      stroke 0.5s ease-out;
        }
        
        .gradient-transition {
          transition: background-image 0.8s ease-out;
        }
        
        /* Enhanced visualizations that respond to color changes */
        .color-reveal-pulse {
          animation: color-reveal-pulse 3s infinite;
        }
        
        @keyframes color-reveal-pulse {
          0%, 100% { opacity: 0.6; }
          50% { opacity: 1; }
        }
      `}</style>
      
      <Navigation scrolled={scrolled} currentSection={currentSection} colors={colors} colorProgress={colorProgress} />
      <StorytellingCursor colors={colors} />
      {/* <SectionIndicator currentSection={currentSection} colors={colors} /> */}
      <main className="relative will-change-transform">
        <HeroSection isLowPerformanceDevice={isLowPerformanceDevice} colors={colors} colorProgress={colorProgress} />
        <JourneySection isLowPerformanceDevice={isLowPerformanceDevice} colors={colors} colorProgress={colorProgress} />
        <ResultsSection isLowPerformanceDevice={isLowPerformanceDevice} colors={colors} colorProgress={colorProgress} />
        <CTASection isLowPerformanceDevice={isLowPerformanceDevice} colors={colors} colorProgress={colorProgress} />
      </main>
      
      <Footer colors={colors} colorProgress={colorProgress} />
      
      {/* Enhanced animations with better performance */}
      <style jsx global>{`
        .scroll-section {
          opacity: 0;
          transform: translateY(30px);
          transition: opacity 1.2s ease-out, transform 1.5s ease-out, color 0.5s ease-out, background-color 0.5s ease-out;
        }
        
        .scroll-section.in-view {
          opacity: 1;
          transform: translateY(0);
        }
        
        .story-element {
          opacity: 0;
          transform: translateY(20px);
          transition: opacity 0.8s ease-out, transform 1s ease-out, color 0.5s ease-out, background-color 0.5s ease-out;
          transition-delay: var(--delay, 0s);
        }
        
        .in-view .story-element {
          opacity: 1;
          transform: translateY(0);
        }
        
        @keyframes fadeIn {
          from { opacity: 0; transform: translateY(20px); }
          to { opacity: 1; transform: translateY(0); }
        }
        
        .fade-in {
          animation: fadeIn 1s ease-out forwards;
          animation-delay: var(--delay, 0s);
        }
        
        @keyframes revealFromLeft {
          from { transform: translateX(-100%); }
          to { transform: translateX(0); }
        }
        
        .reveal-left {
          position: relative;
          overflow: hidden;
        }
        
        .reveal-left::after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: var(--color-backgroundDarker);
          transform: translateX(0);
          z-index: 1;
          animation: revealFromLeft 1.2s ease-out forwards;
          animation-delay: var(--delay, 0s);
        }
        
        .in-view .reveal-left::after {
          animation-play-state: running;
        }
        
        @keyframes float {
          0%, 100% { transform: translateY(0); }
          50% { transform: translateY(-10px); }
        }
        
        .float {
          animation: float 6s ease-in-out infinite;
          animation-delay: var(--delay, 0s);
        }
        
        @keyframes pulse {
          0%, 100% { opacity: 0.6; }
          50% { opacity: 1; }
        }
        
        .pulse {
          animation: pulse 4s ease-in-out infinite;
          animation-delay: var(--delay, 0s);
        }
        
        @keyframes gradientFlow {
          0% { background-position: 0% 50%; }
          50% { background-position: 100% 50%; }
          100% { background-position: 0% 50%; }
        }
        
        .gradient-flow {
          background-size: 200% 200%;
          animation: gradientFlow 15s ease infinite;
        }
        
        .story-pattern {
          background-image: url("data:image/svg+xml,%3Csvg width='60' height='60' viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%23a9b1d6' fill-opacity='0.05'%3E%3Cpath d='M36 34v-4h-2v4h-4v2h4v4h2v-4h4v-2h-4zm0-30V0h-2v4h-4v2h4v4h2V6h4V4h-4zM6 34v-4H4v4H0v2h4v4h2v-4h4v-2H6zM6 4V0H4v4H0v2h4v4h2V6h4V4H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
        }
        
        .progress-line {
          position: absolute;
          top: 0;
          left: 50%;
          height: 0;
          width: 2px;
          background: linear-gradient(to bottom, var(--color-magenta), var(--color-blue));
          z-index: 1;
          transform: translateX(-50%);
          transition: height 1s ease-out, background 0.5s ease-out;
        }
        
        .progress-active {
          height: 100%;
        }
      `}</style>
    </div>
  );
}

// Enhanced Navigation with better transitions
function Navigation({ scrolled, currentSection, colors, colorProgress }) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [activeLink, setActiveLink] = useState('hero');
  const [showMobileForm, setShowMobileForm] = useState(false);
  const [mobileFormLoading, setMobileFormLoading] = useState(true);
  
  useEffect(() => {
    // Update active link based on currentSection
    setActiveLink(currentSection);
  }, [currentSection]);

  // Close menu when clicking a link (mobile)
  const handleLinkClick = () => {
    setIsMenuOpen(false);
    setShowMobileForm(false);
  };

  // Toggle mobile waitlist form
  const toggleMobileForm = (e) => {
    e.preventDefault();
    setShowMobileForm(!showMobileForm);
    if (!showMobileForm) {
      setMobileFormLoading(true);
    }
  };

  const handleMobileIframeLoad = () => {
    setMobileFormLoading(false);
  };

  const handleMobileIframeError = () => {
    setMobileFormLoading(false);
    const mobileFormContainer = document.getElementById('mobile-waitlist-form');
    if (mobileFormContainer) {
      const iframe = mobileFormContainer.querySelector('iframe');
      if (iframe) {
        const errorDiv = document.createElement('div');
        errorDiv.className = 'p-6 text-center';
        errorDiv.innerHTML = `
          <div class="text-[var(--color-red)] mb-3">Unable to load the form</div>
          <p class="mb-3">Please use the direct link instead:</p>
          
          <a href="https://airtable.com/app4R7YLW3rB68X6E/pagkPgY1d5nWZrwea/form" target="_blank" 
            rel="noopener noreferrer"
            class="px-3 py-1.5 bg-[var(--color-backgroundLighter)] hover:bg-[var(--color-selection)] rounded-lg transition-colors text-sm inline-block">
            Open Airtable Form
          </a>
        `;
        
        iframe.parentNode.replaceChild(errorDiv, iframe);
      }
    }
  };

  return (
    <nav className={`fixed w-full top-0 z-[1000] transition-all duration-500 ease-in-out ${
      currentSection === 'hero' && !isMenuOpen ? 
        'bg-transparent py-5 md:py-6' : 
        'bg-[var(--color-background)]/95 backdrop-blur-md py-3 md:py-4 shadow-lg'
    }`}>
      <div className="max-w-[1400px] mx-auto px-6 md:px-8 lg:px-16 flex justify-between items-center">
        <a href="#hero" className="relative group z-20">
          <div className="font-sans text-2xl md:text-3xl font-bold tracking-wide relative overflow-hidden">
            <span className="bg-clip-text text-transparent bg-gradient-to-r from-[var(--color-magenta)] to-[var(--color-blue)] flex items-center transition-all duration-300 group-hover:scale-105 origin-left color-transition">
              <span className="font-sans tracking-[0.05em] mr-1">ARCHII</span>
              <span className="text-[var(--color-foreground)] opacity-70 text-xl mr-3">•</span>
            </span>
            <div className="absolute bottom-[-3px] left-0 w-0 h-[2px] bg-gradient-to-r from-[var(--color-magenta)] to-[var(--color-blue)] transition-all duration-500 ease-out group-hover:w-full color-transition"></div>
          </div>
        </a>

        {/* Desktop Navigation */}
        <div className="hidden md:flex items-center gap-6 lg:gap-10">
          {[
            { href: '#journey', label: 'Your Journey' },
            { href: '#results', label: 'Results' },
          ].map((link) => (
            <a 
              key={link.href}
              href={link.href} 
              className={`font-medium text-sm relative py-2 px-1 transition-all duration-300 group overflow-hidden color-transition ${
                activeLink === link.href.substring(1) ? 'text-[var(--color-foreground)]' : 'text-[var(--color-comment)] hover:text-[var(--color-foreground)]'
              }`}
            >
              <span className="relative z-10">{link.label}</span>
              <div 
                className={`absolute bottom-0 left-0 h-0.5 bg-gradient-to-r from-[var(--color-cyan)] to-[var(--color-blue)] transition-all duration-500 ease-out color-transition ${
                  activeLink === link.href.substring(1) ? 'w-full' : 'w-0 group-hover:w-full'
                }`}
              ></div>
              <div 
                className={`absolute inset-0 bg-[var(--color-selection)]/40 translate-y-full transition-transform duration-300 group-hover:translate-y-0 color-transition ${
                  activeLink === link.href.substring(1) ? 'translate-y-0 opacity-10' : ''
                }`}
              ></div>
            </a>
          ))}
          
          <a 
            href="https://airtable.com/app4R7YLW3rB68X6E/pagkPgY1d5nWZrwea/form" 
            target="_blank"
            rel="noopener noreferrer"
            className="ml-1 px-5 py-2 bg-[var(--color-selection)]/50 backdrop-blur-sm border border-[var(--color-magenta)]/20 rounded-full text-sm font-medium transition-all duration-300 hover:-translate-y-0.5 hover:shadow-lg hover:bg-[var(--color-selection)]/60 hover:border-[var(--color-magenta)]/30 color-transition"
            style={{
              borderColor: `rgba(${parseInt(colors.magenta.slice(1, 3), 16)}, ${parseInt(colors.magenta.slice(3, 5), 16)}, ${parseInt(colors.magenta.slice(5, 7), 16)}, ${0.2 + colorProgress * 0.3})`
            }}
          >
            Begin
          </a>
        </div>
        
        {/* Mobile Menu Toggle with enhanced animation */}
        <button 
          className="md:hidden relative z-20 p-2" 
          onClick={() => setIsMenuOpen(!isMenuOpen)}
          aria-label="Toggle menu"
        >
          <div className="w-6 flex flex-col items-end justify-center">
            <span 
              className={`block h-0.5 rounded-full transition-all duration-300 ease-out ${isMenuOpen ? 'w-6 rotate-45 translate-y-1' : 'w-6 mb-1'}`}
              style={{ backgroundColor: 'var(--color-foreground)' }}
            ></span>
            <span 
              className={`block h-0.5 rounded-full transition-all duration-300 ease-out ${isMenuOpen ? 'w-6 opacity-0' : 'w-4 mb-1'}`}
              style={{ backgroundColor: 'var(--color-foreground)' }}
            ></span>
            <span 
              className={`block h-0.5 rounded-full transition-all duration-300 ease-out ${isMenuOpen ? 'w-6 -rotate-45 -translate-y-1' : 'w-5'}`}
              style={{ backgroundColor: 'var(--color-foreground)' }}
            ></span>
          </div>
        </button>
      </div>

      {/* Enhanced Mobile Menu with better transitions */}
      <div 
        className={`fixed inset-0 bg-[var(--color-backgroundDarker)]/98 backdrop-blur-lg z-10 pt-24 px-6 flex flex-col transition-all duration-500 ease-in-out ${
          isMenuOpen ? 'opacity-100 translate-y-0' : 'opacity-0 -translate-y-full pointer-events-none'
        }`}
      >
        <div className="flex flex-col items-center gap-8 text-center">
          {[
            { href: '#journey', label: 'Your Journey' },
            { href: '#results', label: 'Results' },
            { href: '#cta', label: 'Begin' }
          ].map((link, index) => (
            <a 
              key={link.href}
              href={link.href} 
              onClick={handleLinkClick}
              className={`text-2xl font-medium relative py-2 transition-all duration-500 ease-out color-transition ${
                isMenuOpen ? 'opacity-100 translate-y-0' : 'opacity-0 -translate-y-4'
              } ${activeLink === link.href.substring(1) ? 'text-[var(--color-foreground)]' : 'text-[var(--color-comment)]'}`}
              style={{ 
                transitionDelay: isMenuOpen ? `${index * 100 + 100}ms` : '0ms',
              }}
            >
              <div className="relative overflow-hidden">
                <span className="block transition-transform duration-300 hover:-translate-y-full">{link.label}</span>
                <span className="absolute top-0 left-0 transform translate-y-full transition-transform duration-300 hover:translate-y-0 text-[var(--color-cyan)]">
                  {link.label}
                </span>
              </div>
              <div className={`mt-1 mx-auto h-0.5 w-12 bg-gradient-to-r from-[var(--color-cyan)] to-[var(--color-blue)] transition-all duration-300 color-transition ${activeLink === link.href.substring(1) ? 'w-full max-w-[100px] opacity-100' : 'w-0 opacity-0'}`}></div>
            </a>
          ))}
          
          <div className="relative">
            <a 
              href="https://airtable.com/app4R7YLW3rB68X6E/pagkPgY1d5nWZrwea/form"
              target="_blank"
              rel="noopener noreferrer"
              className={`mt-4 px-8 py-3 bg-[var(--color-selection)]/50 backdrop-blur-sm border border-[var(--color-magenta)]/20 rounded-full text-lg font-medium hover:bg-[var(--color-selection)]/70 hover:border-[var(--color-magenta)]/40 transition-all duration-500 ease-out inline-block color-transition ${
                isMenuOpen ? 'opacity-100 translate-y-0' : 'opacity-0 -translate-y-4'
              }`}
              style={{ 
                transitionDelay: isMenuOpen ? '400ms' : '0ms',
                borderColor: `rgba(${parseInt(colors.magenta.slice(1, 3), 16)}, ${parseInt(colors.magenta.slice(3, 5), 16)}, ${parseInt(colors.magenta.slice(5, 7), 16)}, ${0.2 + colorProgress * 0.3})`
              }}
            >
              Join Waitlist
            </a>
            
            {/* Mobile Waitlist Form */}
            <div 
              id="mobile-waitlist-form"
              className={`absolute left-1/2 -translate-x-1/2 top-full mt-4 bg-[var(--color-backgroundDarker)]/90 backdrop-blur-lg rounded-xl border border-[var(--color-selection)]/30 shadow-2xl transform origin-top transition-all duration-500 ease-in-out z-50 ${
                showMobileForm ? 'opacity-100 scale-100' : 'opacity-0 scale-75 pointer-events-none'
              }`}
              style={{
                width: "300px",
                maxWidth: "calc(100vw - 40px)"
              }}
            >
              <div className="flex justify-between items-center p-4 border-b border-[var(--color-selection)]/30">
                <h3 className="text-lg font-medium">Join our Waitlist</h3>
                <button
                  onClick={toggleMobileForm}
                  className="p-2 rounded-full hover:bg-[var(--color-selection)]/30 transition-colors"
                >
                  <svg width="14" height="14" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M18 6L6 18M6 6L18 18" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                  </svg>
                </button>
              </div>
              
              {/* Airtable Embed */}
              <div className="h-[350px] overflow-hidden rounded-b-xl">
                <iframe 
                  className="w-full h-[533px] bg-transparent" 
                  src="https://airtable.com/embed/app4R7YLW3rB68X6E/pagkPgY1d5nWZrwea/form"
                  frameBorder="0"
                  onLoad={handleMobileIframeLoad}
                  onError={handleMobileIframeError}
                  title="ARCHII Waitlist Form"
                ></iframe>
              </div>
              
              {/* Loading state */}
              <div 
                className={`absolute inset-0 flex items-center justify-center bg-[var(--color-backgroundDarker)]/80 backdrop-blur-sm rounded-xl transition-opacity duration-300 ${
                  mobileFormLoading ? 'opacity-100' : 'opacity-0 pointer-events-none'
                }`}
              >
                <div className="text-center">
                  <div className="w-8 h-8 border-2 border-[var(--color-magenta)] border-t-transparent rounded-full animate-spin mx-auto mb-3 color-transition"></div>
                  <p className="mb-3">Loading form...</p>
                  <p className="text-sm opacity-80">
                    If the form doesn't load, <a 
                      href="https://airtable.com/app4R7YLW3rB68X6E/pagkPgY1d5nWZrwea/form" 
                      target="_blank"
                      rel="noopener noreferrer"
                      className="underline hover:text-[var(--color-cyan)] transition-colors color-transition"
                    >
                      click here
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
}

// Enhanced Cursor for Storytelling
function StorytellingCursor({ colors }) {
  const cursorRef = useRef(null);
  const [isMobile, setIsMobile] = useState(false);
  const [cursorText, setCursorText] = useState('');
  const [isHovering, setIsHovering] = useState(false);
  
  useEffect(() => {
    // Check if on mobile device
    const checkMobile = () => {
      setIsMobile(window.innerWidth < 768);
    };
    
    // Initial check
    checkMobile();
    
    // Add resize listener
    window.addEventListener('resize', checkMobile);
    
    // Handle cursor movement only if not on mobile
    const handleMouseMove = (e) => {
      if (cursorRef.current && !isMobile) {
        cursorRef.current.style.opacity = '1';
        cursorRef.current.style.transform = `translate(${e.clientX}px, ${e.clientY}px)`;
      }
    };
    
    const handleMouseLeave = () => {
      if (cursorRef.current && !isMobile) {
        cursorRef.current.style.opacity = '0';
      }
    };
    
    // Enhanced hover effects with contextual text for storytelling
    const handleElementHover = (e) => {
      if (cursorRef.current && !isMobile) {
        setIsHovering(true);
        cursorRef.current.classList.add('hover');
        
        // Set cursor text based on element data attributes
        const target = e.currentTarget;
        if (target.dataset.cursorText) {
          setCursorText(target.dataset.cursorText);
        } else {
          setCursorText('');
        }
      }
    };
    
    const handleElementLeave = () => {
      if (cursorRef.current && !isMobile) {
        setIsHovering(false);
        cursorRef.current.classList.remove('hover');
        setCursorText('');
      }
    };
    
    // Add click effect
    const handleMouseDown = () => {
      if (cursorRef.current && !isMobile) {
        cursorRef.current.classList.add('active');
      }
    };
    
    const handleMouseUp = () => {
      if (cursorRef.current && !isMobile) {
        cursorRef.current.classList.remove('active');
      }
    };
    
    // Apply hover effect to interactive elements
    const interactiveElements = document.querySelectorAll('a, button, [data-cursor-text], .interactive');
    interactiveElements.forEach(el => {
      el.addEventListener('mouseenter', handleElementHover);
      el.addEventListener('mouseleave', handleElementLeave);
    });
    
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseleave', handleMouseLeave);
    document.addEventListener('mousedown', handleMouseDown);
    document.addEventListener('mouseup', handleMouseUp);
    
    return () => {
      window.removeEventListener('resize', checkMobile);
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseleave', handleMouseLeave);
      document.removeEventListener('mousedown', handleMouseDown);
      document.removeEventListener('mouseup', handleMouseUp);
      
      interactiveElements.forEach(el => {
        el.removeEventListener('mouseenter', handleElementHover);
        el.removeEventListener('mouseleave', handleElementLeave);
      });
    };
  }, [isMobile]);
  
  // Don't render cursor on mobile
  if (isMobile) return null;
  
  return (
    <div 
      ref={cursorRef} 
      className="fixed pointer-events-none z-[9999] mix-blend-difference opacity-0 -translate-x-1/2 -translate-y-1/2 transition-[width,height,opacity,background] duration-300 ease-out hidden md:flex items-center justify-center"
      style={{
        transition: 'transform 0.2s cubic-bezier(0.16, 1, 0.3, 1), opacity 0.5s ease, width 0.3s ease, height 0.3s ease',
        width: isHovering ? '80px' : '24px',
        height: isHovering ? '80px' : '24px',
        borderRadius: '50%',
        background: isHovering ? 'rgba(187, 154, 247, 0.3)' : 'rgba(187, 154, 247, 0.2)',
      }}
    >
      {cursorText && (
        <span className="text-white text-xs font-medium whitespace-nowrap px-3">
          {cursorText}
        </span>
      )}
      <style jsx>{`
        .hover {
          mix-blend-mode: difference !important;
        }
        .active {
          transform: translate(var(--x), var(--y)) scale(0.9) !important;
          background: rgba(247, 118, 142, 0.4) !important;
        }
      `}</style>
    </div>
  );
}

// Enhanced Section Indicator with better animations and contextual feedback
function SectionIndicator({ currentSection, colors }) {
  // Section labels for better context
  const sections = [
    { id: 'hero', label: 'Home' },
    { id: 'journey', label: 'Journey' },
    { id: 'results', label: 'Results' },
    { id: 'cta', label: 'Begin' }
  ];
  
  const handleDotClick = (section) => {
    const targetSection = document.getElementById(section);
    
    if (targetSection) {
      // Add highlight effect to the section
      targetSection.classList.add('pulse-highlight');
      setTimeout(() => {
        targetSection.classList.remove('pulse-highlight');
      }, 2000);
      
      // Smooth scroll with offset for better positioning
      const navHeight = document.querySelector('nav').offsetHeight;
      const targetPosition = targetSection.getBoundingClientRect().top + window.scrollY;
      
      window.scrollTo({
        top: targetPosition - navHeight - 20, // Additional 20px buffer
        behavior: 'smooth'
      });
    }
  };
  
  return (
    <div className="fixed top-1/2 right-8 -translate-y-1/2 flex flex-col gap-6 z-50 hidden md:flex">
      {sections.map((section, index) => (
        <div 
          key={section.id}
          className="relative flex items-center group cursor-pointer"
          onClick={() => handleDotClick(section.id)}
          data-cursor-text={section.label}
        >
          <div 
            className={`w-3 h-3 rounded-full transition-all duration-500 ease-in-out ${
              currentSection === section.id ? 'bg-[var(--color-cyan)] shadow-[0_0_10px_var(--color-cyan)]' : 'bg-[var(--color-comment)] group-hover:bg-[var(--color-foreground)]'
            }`}
          ></div>
          
          <div className={`absolute right-full mr-4 px-2 py-1 bg-[var(--color-backgroundDarker)]/90 rounded-md text-xs whitespace-nowrap transition-all duration-300 ${
            currentSection === section.id ? 'opacity-100 translate-x-0' : 'opacity-0 translate-x-2 group-hover:opacity-100 group-hover:translate-x-0'
          }`}>
            {section.label}
          </div>
        </div>
      ))}
      
      {/* Visual progress indicator */}
      <div className="absolute top-0 bottom-0 right-1.5 w-0.5 bg-[var(--color-selection)]/20 -z-10 rounded-full overflow-hidden">
        <div 
          className="absolute top-0 left-0 w-full bg-gradient-to-b from-[var(--color-magenta)]/40 to-[var(--color-blue)]/40 transition-all duration-500 ease-out rounded-full"
          style={{
            height: `${
              sections.findIndex(s => s.id === currentSection) / (sections.length - 1) * 100
            }%`
          }}
        ></div>
      </div>
    </div>
  );
}

// Hero Section
function HeroSection({ isLowPerformanceDevice, colors, colorProgress }) {
  const [showForm, setShowForm] = useState(false);
  const formRef = useRef(null);
  const [formLoading, setFormLoading] = useState(true);
  const heroRef = useRef(null);
  
  // Ensure visibility on mobile devices
  useEffect(() => {
    const heroElement = heroRef.current;
    if (heroElement && isLowPerformanceDevice) {
      // Force all elements to be visible
      const storyElements = heroElement.querySelectorAll('.story-element');
      storyElements.forEach((el) => {
        el.style.opacity = '1';
        el.style.transform = 'translateY(0)';
      });
      
      const titleElement = heroElement.querySelector('.hero-title');
      const storyCard = heroElement.querySelector('.story-card');
      const ctaButton = heroElement.querySelector('button');
      
      if (titleElement) {
        titleElement.style.opacity = '1';
        titleElement.style.transform = 'translateY(0)';
      }
      
      if (storyCard) {
        storyCard.style.opacity = '1';
        storyCard.style.transform = 'translateY(0)';
      }
      
      if (ctaButton) {
        ctaButton.style.opacity = '1';
        ctaButton.style.transform = 'translateY(0)';
      }
    }
  }, [isLowPerformanceDevice]);
  
  // Enhance scroll-driven animations
  useEffect(() => {
    if (isLowPerformanceDevice) return;
    
    const heroElement = heroRef.current;
    if (!heroElement) return;
    
    // Set initial state to ensure visibility
    const titleElement = heroElement.querySelector('.hero-title');
    const storyCard = heroElement.querySelector('.story-card');
    const ctaButton = heroElement.querySelector('button');
    
    if (titleElement) {
      titleElement.style.opacity = '1';
      titleElement.style.transform = 'translateY(0)';
    }
    
    if (storyCard) {
      storyCard.style.opacity = '1';
      storyCard.style.transform = 'translateY(0)';
    }
    
    if (ctaButton) {
      ctaButton.style.opacity = '1';
      ctaButton.style.transform = 'translateY(0)';
    }
    
    const animateOnScroll = () => {
      const scrollY = window.scrollY;
      const heroHeight = heroElement.offsetHeight;
      // Only start animations after scrolling down a bit
      if (scrollY < 100) {
        // Reset to visible state when at top
        if (titleElement) {
          titleElement.style.opacity = '1';
          titleElement.style.transform = 'translateY(0)';
        }
        
        if (storyCard) {
          storyCard.style.opacity = '1';
          storyCard.style.transform = 'translateY(0)';
        }
        
        return;
      }
      
      const scrollProgress = Math.min(scrollY / (heroHeight * 0.75), 1); // Slowed down scroll progress to ensure content remains visible longer
      
      // Parallax effects for storytelling elements - reduce transformation amount to keep items visible
      if (titleElement) {
        titleElement.style.transform = `translateY(${scrollProgress * 20}px)`; // Reduced from 50px to 20px
        titleElement.style.opacity = Math.max(1 - scrollProgress * 0.7, 0.3); // Set minimum opacity to ensure visibility
      }
      
      // Fade out story cards on scroll with slower rate and minimum opacity
      if (storyCard) {
        storyCard.style.transform = `translateY(${scrollProgress * 15}px)`; // Reduced from 30px to 15px
        storyCard.style.opacity = Math.max(1 - scrollProgress * 0.6, 0.4); // Slower fade rate and minimum opacity
      }
      
      // Enhance scroll indicator visibility based on scroll position
      const scrollIndicator = heroElement.querySelector('.scroll-indicator');
      if (scrollIndicator) {
        scrollIndicator.style.opacity = Math.max(1 - (scrollProgress * 2), 0);
      }
    };
    
    window.addEventListener('scroll', animateOnScroll);
    // Initial call to set proper positions when the page loads
    requestAnimationFrame(animateOnScroll);
    return () => window.removeEventListener('scroll', animateOnScroll);
  }, [isLowPerformanceDevice]);
  
  // Toggle waitlist form
  const toggleForm = (e) => {
    e.preventDefault();
    setShowForm(!showForm);
    if (!showForm) {
      setFormLoading(true);
    }
  };

  const handleIframeLoad = () => {
    setFormLoading(false);
  };

  const handleIframeError = () => {
    setFormLoading(false);
    if (formRef.current) {
      const iframe = formRef.current.querySelector('iframe');
      if (iframe) {
        const errorDiv = document.createElement('div');
        errorDiv.className = 'p-8 text-center';
        errorDiv.innerHTML = `
          <div class="text-[var(--color-red)] mb-4">Unable to load the form</div>
          <p class="mb-4">Please use the direct link instead:</p>
          
          <a href="https://airtable.com/app4R7YLW3rB68X6E/pagkPgY1d5nWZrwea/form" target="_blank" 
            rel="noopener noreferrer"
            class="px-4 py-2 bg-[var(--color-selection)]/40 hover:bg-[var(--color-selection)]/60 rounded-lg transition-colors inline-block">
            Open Airtable Form
          </a>
        `;
        
        iframe.parentNode.replaceChild(errorDiv, iframe);
      }
    }
  };
  
  // Ensure initial visibility
  useEffect(() => {
    // Make hero section elements visible immediately
    const heroElement = heroRef.current;
    if (!heroElement) return;
    
    const storyElements = heroElement.querySelectorAll('.story-element');
    storyElements.forEach((el, index) => {
      el.style.opacity = '1';
      el.style.transform = 'translateY(0)';
    });
    
    // Initial scroll position check
    if (!isLowPerformanceDevice) {
      const sections = document.querySelectorAll('.scroll-section');
      sections.forEach(section => {
        const rect = section.getBoundingClientRect();
        const isInView = rect.top < window.innerHeight * 0.75 && rect.bottom > 0;
        if (isInView) {
          section.classList.add('in-view');
        }
      });
    }
  }, [isLowPerformanceDevice]);
  
  return (
    <section 
      id="hero" 
      ref={heroRef}
      className="relative flex flex-col justify-center min-h-screen w-full overflow-hidden z-20 pt-28 pb-16 md:pt-32 md:pb-24"
    >
      {/* Enhanced storytelling background */}
      <div className="absolute inset-0 bg-[var(--color-background)] z-0">
        {/* Subtle gradients to represent emotional complexity */}
        <div className="absolute top-0 right-0 w-1/2 h-1/2 bg-gradient-to-br from-[var(--color-magenta)]/[0.02] to-transparent rounded-bl-[100px] opacity-70"></div>
        <div className="absolute bottom-0 left-0 w-1/2 h-1/2 bg-gradient-to-tr from-[var(--color-blue)]/[0.02] to-transparent rounded-tr-[100px] opacity-70"></div>
      </div>
      
      <div className="relative z-30 text-center w-full max-w-[1200px] mx-auto px-6 sm:px-10 flex flex-col items-center justify-center space-y-10 md:space-y-12">
        <div className="story-element" style={{"--delay": "0.2s", "opacity": "1", "transform": "translateY(0)"}}>
          {/* Enhanced storytelling badge */}
          <div className="inline-block mb-6 px-4 py-2 rounded-full bg-[var(--color-selection)]/20 backdrop-blur-sm border border-[var(--color-selection)]/20 text-xs uppercase tracking-widest text-[var(--color-foreground)]/80 gradient-flow">
            <span className="bg-clip-text text-transparent bg-gradient-to-r from-[var(--color-foreground)] via-[var(--color-cyan)]/80 to-[var(--color-foreground)]">No Labels. Just You.</span>
          </div>
          
          {/* Enhanced headline with story focus */}
          <h1 className="hero-title text-5xl md:text-6xl lg:text-7xl xl:text-8xl leading-tight font-light tracking-tight mb-8 md:mb-10 text-[var(--color-foreground)]">
            <span className="inline-block relative">
              <span className="font-sans font-light tracking-widest uppercase text-[0.9em] inline-block transition-all duration-700 hover:text-[var(--color-foreground)]">therapy</span>
              <span className="absolute -bottom-4 md:-bottom-5 left-0 w-full h-[1px] bg-gradient-to-r from-transparent via-[var(--color-foreground)]/20 to-transparent"></span>
            </span>{' '}
            <span className="font-serif font-bold italic inline-block transition-all duration-700 bg-gradient-to-r from-[var(--color-magenta)] to-[var(--color-blue)] bg-clip-text text-transparent hover:from-[var(--color-cyan)] hover:to-[var(--color-magenta)] gradient-flow relative">
              without boxes
              <span className="absolute -bottom-1 left-0 w-full h-1 bg-gradient-to-r from-[var(--color-magenta)]/30 to-[var(--color-blue)]/30 rounded-full"></span>
            </span>
          </h1>
        </div>
        
        <div className="story-element w-full max-w-[800px] mx-auto" style={{"--delay": "0.4s", "opacity": "1", "transform": "translateY(0)"}}>
          <p className="text-xl md:text-2xl lg:text-3xl text-[var(--color-comment)] leading-relaxed mb-16">
            AI therapy that syncs with your emotions, personality, and life stage — not clinical labels or categories.
          </p>
        </div>
        
        <div className="story-element" style={{"--delay": "0.8s", "opacity": "1", "transform": "translateY(0)"}}>
          {/* Enhanced CTA with storytelling context */}
          <a 
            href="https://airtable.com/app4R7YLW3rB68X6E/pagkPgY1d5nWZrwea/form"
            target="_blank"
            rel="noopener noreferrer"
            className="group relative overflow-hidden px-12 py-6 rounded-lg text-lg md:text-xl bg-[var(--color-selection)]/60 backdrop-blur-sm border border-[var(--color-magenta)]/40 font-medium tracking-wide transition-all duration-700 ease-out hover:shadow-[0_10px_40px_var(--color-magenta)/15,0_0_15px_var(--color-magenta)/10] hover:border-[var(--color-magenta)]/40 hover:-translate-y-1 z-10 inline-block"
            data-cursor-text="Join"
          >
            <span className="relative z-10 transition-all duration-500 flex items-center gap-3">
              <span>Join Waitlist</span>
            </span>
            
            <span className="absolute inset-0 translate-x-[-100%] group-hover:translate-x-[100%] transition-transform duration-1000 bg-gradient-to-r from-transparent via-[var(--color-foreground)]/5 to-transparent"></span>
            
            <span className="absolute inset-0 opacity-0 group-hover:opacity-100 transition-opacity duration-700 bg-gradient-to-r from-[var(--color-magenta)]/10 to-[var(--color-blue)]/10 rounded-lg blur-md"></span>
          </a>
          
          {/* Enhanced social proof with narrative elements */}
          <div className="mt-5 text-[var(--color-comment)]/80 text-sm tracking-wide flex flex-col items-center">
            <div className="flex items-center justify-center gap-3 mb-2">
              <div className="flex -space-x-2">
                {[...Array(4)].map((_, i) => (
                  <div 
                    key={i}
                    className="w-7 h-7 rounded-full flex items-center justify-center text-xs shadow-sm"
                    style={{
                      background: `var(--color-${['magenta', 'blue', 'cyan', 'green'][i]})20`,
                      borderColor: `var(--color-${['magenta', 'blue', 'cyan', 'green'][i]})30`,
                      borderWidth: '1px',
                      borderStyle: 'solid'
                    }}
                  >
                    {['L', 'N', 'S', '+'][i]}
                  </div>
                ))}
              </div>
              <span>Join pioneers exploring a new paradigm in mental health</span>
            </div>
            <div className="flex justify-center gap-4 items-center">
           
              <div className="h-4 w-px bg-[var(--color-selection)]/30"></div>
              <div className="text-[var(--color-cyan)] flex items-center gap-1">
                <svg className="w-4 h-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"></path>
                </svg>
                <span>Limited early access spots available</span>
              </div>
            </div>
            
            {/* Enhanced benefit pills */}
            <div className="mt-3 flex flex-wrap justify-center gap-2">
              <div className="px-3 py-1 rounded-full bg-[var(--color-magenta)]/10 border border-[var(--color-magenta)]/20 text-xs flex items-center gap-1.5 transition-all duration-300 hover:bg-[var(--color-magenta)]/15">
                <svg className="w-3 h-3 text-[var(--color-magenta)]" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
                <span>Emotion-Focused</span>
              </div>
              <div className="px-3 py-1 rounded-full bg-[var(--color-blue)]/10 border border-[var(--color-blue)]/20 text-xs flex items-center gap-1.5 transition-all duration-300 hover:bg-[var(--color-blue)]/15">
                <svg className="w-3 h-3 text-[var(--color-blue)]" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
                <span>Personality-Matched</span>
              </div>
              <div className="px-3 py-1 rounded-full bg-[var(--color-cyan)]/10 border border-[var(--color-cyan)]/20 text-xs flex items-center gap-1.5 transition-all duration-300 hover:bg-[var(--color-cyan)]/15">
                <svg className="w-3 h-3 text-[var(--color-cyan)]" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
                <span>Behavior-Driven</span>
              </div>
            </div>
          </div>
        </div>
        
        {/* Airtable Embed Form - Inline Version */}
        <div 
          ref={formRef}
          className={`mt-8 bg-[var(--color-backgroundDarker)]/95 backdrop-blur-xl rounded-xl border border-[var(--color-selection)]/30 shadow-[0_15px_60px_rgba(0,0,0,0.4),0_0_15px_var(--color-magenta)/10] transform origin-top transition-all duration-500 ease-in-out ${
            showForm ? 'max-h-[600px] opacity-100' : 'max-h-0 opacity-0 overflow-hidden'
          }`}
          style={{
            width: "100%",
            maxWidth: "560px",
            margin: "0 auto"
          }}
        >
          <div className={`transition-all duration-500 ${showForm ? 'opacity-100' : 'opacity-0'}`}>
            <div className="flex justify-between items-center p-4 border-b border-[var(--color-selection)]/30">
              <h3 className="text-xl font-medium">Join Our Waitlist</h3>
              <button
                onClick={toggleForm}
                className="p-2 rounded-full hover:bg-[var(--color-selection)]/30 transition-colors"
                aria-label="Close form"
              >
                <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M18 6L6 18M6 6L18 18" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
              </button>
            </div>
            
            {/* Airtable Embed */}
            <div className="h-[450px] overflow-hidden rounded-b-xl">
              <iframe 
                className="w-full h-[533px] bg-transparent" 
                src="https://airtable.com/embed/app4R7YLW3rB68X6E/pagkPgY1d5nWZrwea/form"
                frameBorder="0"
                onLoad={handleIframeLoad}
                onError={handleIframeError}
                title="ARCHII Waitlist Form"
              ></iframe>
            </div>
            
            {/* Loading state */}
            <div 
              className={`absolute inset-0 flex items-center justify-center bg-[var(--color-backgroundDarker)]/80 backdrop-blur-sm rounded-xl transition-opacity duration-300 ${
                formLoading ? 'opacity-100' : 'opacity-0 pointer-events-none'
              }`}
            >
              <div className="text-center">
                <div className="w-8 h-8 border-2 border-[var(--color-magenta)] border-t-transparent rounded-full animate-spin mx-auto mb-3"></div>
                <p className="mb-4">Loading form...</p>
                <p className="text-sm opacity-80">
                  If the form doesn't load, <a 
                    href="https://airtable.com/app4R7YLW3rB68X6E/pagkPgY1d5nWZrwea/form" 
                    target="_blank"
                    rel="noopener noreferrer"
                    className="underline hover:text-[var(--color-cyan)] transition-colors"
                  >
                    click here to open directly
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      {/* Add a scroll indicator */}
     
    </section>
  );
}

// Journey Section
function JourneySection({ isLowPerformanceDevice, colors, colorProgress }) {
  // Journey steps from the second document
  const journeySteps = [
    {
      title: "Emotion Mapping",
      description: "Instead of starting with a diagnosis, we begin by mapping your emotional patterns. We track how you actually feel day-to-day, identifying your unique emotional signature rather than squeezing you into clinical categories.",
      icon: "01",
      color: "var(--color-blue)",
      visual: "Assessment"
    },
    {
      title: "Personality Alignment",
      description: "ARCHII adapts to your personality type. Whether you're analytical, creative, practical, or relational, our AI tailors its communication style and therapeutic approach to match how you naturally process information and emotions.",
      icon: "02",
      color: "var(--color-magenta)",
      visual: "Exploration"
    },
    {
      title: "Life-Stage Calibration",
      description: "Your needs at 25 differ from your needs at 45. ARCHII adjusts to your current life stage, whether you're navigating career beginnings, parenthood, relationship changes, or major life transitions.",
      icon: "03",
      color: "var(--color-green)",
      visual: "Growth"
    },
    {
      title: "Behavioral Integration",
      description: "Real change happens through action. ARCHII helps you translate emotional insights into concrete behavioral shifts, with practical strategies that work with your lifestyle, not against it.",
      icon: "04",
      color: "var(--color-cyan)",
      visual: "Evolution"
    },
    {
      title: "Continuous Adaptation",
      description: "Your relationship with ARCHII evolves as you do. Our system learns from every interaction, becoming increasingly aligned with your specific needs and measuring progress based on your growth, not arbitrary clinical benchmarks.",
      icon: "05",
      color: "var(--color-yellow)",
      visual: "Progress"
    }
  ];

  // Scroll animation ref
  const sectionRef = useRef(null);
  const progressLineRef = useRef(null);
  
  // Handle scroll animations for narrative progression
  useEffect(() => {
    const section = sectionRef.current;
    const progressLine = progressLineRef.current;
    if (!section || !progressLine) return;
    
    // For mobile/low performance devices, make content visible immediately
    if (isLowPerformanceDevice) {
      section.classList.add('in-view');
      progressLine.style.height = '100%';
      
      const steps = section.querySelectorAll('.journey-step');
      steps.forEach(step => {
        step.style.opacity = '1';
      });
      return;
    }
    
    let ticking = false;
    
    const handleScroll = () => {
      if (!ticking) {
        requestAnimationFrame(() => {
          const scrollPosition = window.scrollY;
          const sectionTop = section.offsetTop;
          const sectionHeight = section.offsetHeight;
          const windowHeight = window.innerHeight;
          
          // Calculate scroll progress within section
          const startPosition = sectionTop - windowHeight;
          const endPosition = sectionTop + sectionHeight - windowHeight/2;
          const scrollProgress = Math.max(0, Math.min(1, (scrollPosition - startPosition) / (endPosition - startPosition)));
          
          // Animate progress line
          progressLine.style.height = `${scrollProgress * 100}%`;
          
          // Cache DOM query result
          const steps = section.querySelectorAll('.journey-step');
          
          // Use fewer DOM operations by calculating in memory first
          steps.forEach((step, index) => {
            // Calculate when this step should be highlighted
            const stepPosition = index / (steps.length - 1);
            const stepActivationPoint = stepPosition - 0.1;
            const stepDeactivationPoint = stepPosition + 0.1;
            
            const shouldBeActive = scrollProgress > stepActivationPoint && scrollProgress < stepDeactivationPoint;
            const isCurrentlyActive = step.classList.contains('journey-step-active');
            
            // Apply color intensity based on step position compared to scroll position
            const stepColorIntensity = Math.max(0, Math.min(1, 
              1 - Math.abs(stepPosition - scrollProgress) * 2.5
            ));
            
            // Set custom color intensity for this step
            step.style.setProperty('--step-color-intensity', stepColorIntensity.toFixed(2));
            
            // Only modify DOM if needed
            if (shouldBeActive && !isCurrentlyActive) {
              step.classList.add('journey-step-active');
            } else if (!shouldBeActive && isCurrentlyActive) {
              step.classList.remove('journey-step-active');
            }
          });
          
          ticking = false;
        });
        
        ticking = true;
      }
    };
    
    window.addEventListener('scroll', handleScroll, { passive: true });
    handleScroll(); // Initial check
    
    return () => window.removeEventListener('scroll', handleScroll);
  }, [isLowPerformanceDevice]);
  
  // Render step visualizations
  const renderVisual = (type, stepColor, intensity = 1) => {
    // Apply color intensity to the visualization
    const applyColorIntensity = (baseColor, intensity) => {
      // This function can be used to modulate color opacity or transform grayscale to color
      return baseColor;
    };
    
    switch(type) {
      case 'Assessment':
        return (
          <div className="relative w-full h-full flex items-center justify-center">
            <svg viewBox="0 0 200 200" className="w-full h-full">
              <defs>
                <linearGradient id="assessment-grad" x1="0%" y1="0%" x2="100%" y2="100%">
                  <stop offset="0%" stopColor="var(--color-blue)" stopOpacity="0.6" />
                  <stop offset="100%" stopColor="var(--color-blue)" stopOpacity="0.2" />
                </linearGradient>
              </defs>
              
              {/* Human figure representing the individual */}
              <circle cx="100" cy="70" r="25" fill="url(#assessment-grad)" className="pulse" />
              <circle cx="100" cy="70" r="15" fill="var(--color-blue)" fillOpacity="0.3" />
              
              {/* Assessment paths */}
              <path 
                d="M100,100 C130,120 150,100 160,130 S150,170 120,160 S90,130 100,100" 
                fill="none" 
                stroke="var(--color-blue)" 
                strokeWidth="2" 
                strokeDasharray="250" 
                strokeDashoffset="250"
                opacity="0.6"
                className="animate-path"
              />
              
              <path 
                d="M100,100 C70,120 50,100 40,130 S50,170 80,160 S110,130 100,100" 
                fill="none" 
                stroke="var(--color-blue)" 
                strokeWidth="2" 
                strokeDasharray="250" 
                strokeDashoffset="250"
                opacity="0.6"
                className="animate-path"
                style={{animationDelay: "0.5s"}}
              />
              
              <path 
                d="M100,100 L100,160" 
                fill="none" 
                stroke="var(--color-blue)" 
                strokeWidth="2" 
                strokeDasharray="60" 
                strokeDashoffset="60"
                opacity="0.6"
                className="animate-path"
                style={{animationDelay: "1s"}}
              />
            </svg>
            
            <style jsx>{`
              @keyframes draw-path {
                to { stroke-dashoffset: 0; }
              }
              
              .animate-path {
                animation: draw-path 2s ease-out forwards;
              }
            `}</style>
          </div>
        );
      case 'Exploration':
        return (
          <div className="relative w-full h-full flex items-center justify-center">
            <svg viewBox="0 0 200 200" className="w-full h-full">
              <defs>
                <linearGradient id="exploration-grad" x1="0%" y1="0%" x2="100%" y2="100%">
                  <stop offset="0%" stopColor="var(--color-magenta)" stopOpacity="0.6" />
                  <stop offset="100%" stopColor="var(--color-magenta)" stopOpacity="0.2" />
                </linearGradient>
              </defs>
              
              {/* Network of connections representing pattern recognition */}
              <circle cx="100" cy="100" r="40" fill="url(#exploration-grad)" className="pulse" />
              
              {/* Connection points */}
              {[...Array(6)].map((_, i) => {
                const angle = (i / 6) * Math.PI * 2;
                const x = 100 + Math.cos(angle) * 75;
                const y = 100 + Math.sin(angle) * 75;
                
                return (
                  <g key={i}>
                    <circle 
                      cx={x} 
                      cy={y} 
                      r="8" 
                      fill="var(--color-magenta)" 
                      fillOpacity="0.5" 
                      className="pulse"
                      style={{animationDelay: `${i * 0.2}s`}}
                    />
                    <line 
                      x1="100" 
                      y1="100" 
                      x2={x} 
                      y2={y} 
                      stroke="var(--color-magenta)" 
                      strokeWidth="1.5" 
                      strokeDasharray="80" 
                      strokeDashoffset="80"
                      opacity="0.4"
                      className="animate-connect"
                      style={{animationDelay: `${i * 0.2}s`}}
                    />
                  </g>
                );
              })}
            </svg>
            
            <style jsx>{`
              @keyframes connect {
                to { stroke-dashoffset: 0; }
              }
              
              .animate-connect {
                animation: connect 1.5s ease-out forwards;
              }
            `}</style>
          </div>
        );
      case 'Growth':
        return (
          <div className="relative w-full h-full flex items-center justify-center">
            <svg viewBox="0 0 200 200" className="w-full h-full">
              <defs>
                <linearGradient id="growth-grad" x1="0%" y1="0%" x2="100%" y2="100%">
                  <stop offset="0%" stopColor="var(--color-green)" stopOpacity="0.6" />
                  <stop offset="100%" stopColor="var(--color-green)" stopOpacity="0.2" />
                </linearGradient>
              </defs>
              
              {/* Growth visualization */}
              <path 
                d="M20,160 C60,120 70,140 90,100 C110,60 130,80 140,60 C150,40 170,20 180,20" 
                fill="none" 
                stroke="var(--color-green)" 
                strokeWidth="2" 
                strokeDasharray="300" 
                strokeDashoffset="300"
                opacity="0.7"
                className="animate-grow"
              />
              
              {/* Growth nodes */}
              {[20, 90, 140, 180].map((x, i) => {
                const y = i === 0 ? 160 : i === 1 ? 100 : i === 2 ? 60 : 20;
                
                return (
                  <circle 
                    key={i}
                    cx={x} 
                    cy={y} 
                    r="6" 
                    fill="var(--color-green)" 
                    opacity="0"
                    className="animate-node"
                    style={{animationDelay: `${i * 0.5 + 0.5}s`}}
                  />
                );
              })}
              
              {/* Growth metaphor */}
              <path 
                d="M100,160 L100,100" 
                fill="none" 
                stroke="var(--color-green)" 
                strokeWidth="3" 
                strokeDasharray="60" 
                strokeDashoffset="60"
                className="animate-grow"
              />
              
              <path 
                d="M100,140 C80,130 70,140 60,130" 
                fill="none" 
                stroke="var(--color-green)" 
                strokeWidth="2" 
                strokeDasharray="50" 
                strokeDashoffset="50"
                className="animate-grow"
                style={{animationDelay: "0.3s"}}
              />
              
              <path 
                d="M100,120 C120,110 130,120 140,110" 
                fill="none" 
                stroke="var(--color-green)" 
                strokeWidth="2" 
                strokeDasharray="50" 
                strokeDashoffset="50"
                className="animate-grow"
                style={{animationDelay: "0.6s"}}
              />
              
              <circle cx="100" cy="90" r="15" fill="url(#growth-grad)" opacity="0" className="animate-node" style={{animationDelay: "0.9s"}} />
            </svg>
            
            <style jsx>{`
              @keyframes grow {
                to { stroke-dashoffset: 0; }
              }
              
              .animate-grow {
                animation: grow 2s ease-out forwards;
              }
              
              @keyframes node-appear {
                from { opacity: 0; transform: scale(0); }
                to { opacity: 1; transform: scale(1); }
              }
              
              .animate-node {
                animation: node-appear 1s ease-out forwards;
              }
            `}</style>
          </div>
        );
      case 'Evolution':
        return (
          <div className="relative w-full h-full flex items-center justify-center">
            <svg viewBox="0 0 200 200" className="w-full h-full">
              <defs>
                <linearGradient id="evolution-grad" x1="0%" y1="0%" x2="100%" y2="100%">
                  <stop offset="0%" stopColor="var(--color-cyan)" stopOpacity="0.6" />
                  <stop offset="100%" stopColor="var(--color-cyan)" stopOpacity="0.2" />
                </linearGradient>
              </defs>
              
              {/* Evolution visualization */}
              <path 
                d="M40,130 Q60,130 60,100 T80,70 T100,40 T120,70 T140,100 T160,130" 
                fill="none" 
                stroke="var(--color-cyan)" 
                strokeWidth="2" 
                strokeDasharray="300" 
                strokeDashoffset="300"
                className="animate-evolution"
              />
              
              <circle cx="40" cy="130" r="8" fill="var(--color-cyan)" opacity="0.4" />
              <circle cx="160" cy="130" r="8" fill="var(--color-cyan)" opacity="0.4" />
              
              {/* Evolution metaphor */}
              <circle cx="70" cy="160" r="25" fill="none" stroke="var(--color-cyan)" strokeWidth="2" opacity="0.3" />
              <circle cx="130" cy="160" r="25" fill="url(#evolution-grad)" className="pulse" />
              
              <path 
                d="M85,160 L115,160" 
                fill="none" 
                stroke="var(--color-cyan)" 
                strokeWidth="2" 
                strokeDasharray="30" 
                strokeDashoffset="30"
                className="animate-evolution"
                style={{animationDelay: "1s"}}
              />
              
              <polygon 
                points="108,155 115,160 108,165" 
                fill="var(--color-cyan)" 
                opacity="0"
                className="animate-arrow"
              />
            </svg>
            
            <style jsx>{`
              @keyframes evolution {
                to { stroke-dashoffset: 0; }
              }
              
              .animate-evolution {
                animation: evolution 2s ease-out forwards;
              }
              
              @keyframes arrow-appear {
                from { opacity: 0; }
                to { opacity: 1; }
              }
              
              .animate-arrow {
                animation: arrow-appear 0.5s ease-out forwards;
                animation-delay: 1.5s;
              }
            `}</style>
          </div>
        );
      case 'Progress':
        return (
          <div className="relative w-full h-full flex items-center justify-center">
            <svg viewBox="0 0 200 200" className="w-full h-full">
              <defs>
                <linearGradient id="progress-grad" x1="0%" y1="0%" x2="100%" y2="100%">
                  <stop offset="0%" stopColor="var(--color-yellow)" stopOpacity="0.6" />
                  <stop offset="100%" stopColor="var(--color-yellow)" stopOpacity="0.2" />
                </linearGradient>
              </defs>
              
              {/* Progress chart */}
              <rect x="40" y="40" width="120" height="120" fill="none" stroke="var(--color-yellow)" strokeWidth="1" strokeOpacity="0.3" />
              
              {/* Progress line */}
              <path 
                d="M40,160 L70,120 L100,140 L130,80 L160,40" 
                fill="none" 
                stroke="var(--color-yellow)" 
                strokeWidth="2"
                strokeDasharray="300"
                strokeDashoffset="300"
                className="animate-progress"
              />
              
              {/* Data points */}
              {[
                {x: 70, y: 120},
                {x: 100, y: 140},
                {x: 130, y: 80}
              ].map((point, i) => (
                <circle 
                  key={i}
                  cx={point.x} 
                  cy={point.y} 
                  r="5" 
                  fill="var(--color-yellow)" 
                  opacity="0"
                  className="animate-point"
                  style={{animationDelay: `${i * 0.5 + 1}s`}}
                />
              ))}
              
              {/* Starting and ending points */}
              <circle cx="40" cy="160" r="5" fill="var(--color-yellow)" opacity="0.3" />
              <circle cx="160" cy="40" r="8" fill="url(#progress-grad)" className="pulse" />
            </svg>
            
            <style jsx>{`
              @keyframes progress {
                to { stroke-dashoffset: 0; }
              }
              
              .animate-progress {
                animation: progress 2s ease-out forwards;
              }
              
              @keyframes point-appear {
                from { opacity: 0; transform: scale(0); }
                to { opacity: 1; transform: scale(1); }
              }
              
              .animate-point {
                animation: point-appear 0.5s ease-out forwards;
              }
            `}</style>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <section 
      id="journey" 
      ref={sectionRef}
      className="relative z-20 pt-32 md:pt-40 lg:pt-48 pb-24 md:pb-32 -mt-10 md:-mt-20 min-h-screen scroll-section"
    >
      {/* Enhanced background with story-relevant patterns */}
      <div className="absolute inset-0 bg-gradient-to-b from-[var(--color-background)] to-[var(--color-background)]"></div>
      <div className="absolute inset-0 story-pattern opacity-[0.05]"></div>
      
      <div className="max-w-[1400px] mx-auto px-6 md:px-8 lg:px-16 relative z-10">
        {/* Enhanced section title with story focus */}
        <div className="mb-16 md:mb-20 lg:mb-24 relative story-element">
          <div className="inline-block mb-3 px-3 py-1.5 rounded-full bg-[var(--color-selection)]/20 backdrop-blur-sm border border-[var(--color-selection)]/20 text-xs uppercase tracking-widest text-[var(--color-foreground)]/70">Your Journey</div>
          <h2 className="text-3xl md:text-4xl lg:text-5xl font-serif font-bold italic bg-gradient-to-r from-[var(--color-foreground)] to-[var(--color-comment)] text-transparent bg-clip-text mb-4">
            Your Emotions, Not Your Diagnosis
          </h2>
          <div className="h-1 w-24 mt-2 mb-6 rounded-full bg-gradient-to-r from-[var(--color-magenta)]/50 to-[var(--color-blue)]/50 transition-all duration-500 hover:w-32 hover:shadow-[0_0_10px_rgba(187,154,247,0.5)]"></div>
          <p className="font-sans text-lg md:text-xl text-[var(--color-comment)] max-w-[700px] leading-relaxed relative">
            <span className="relative z-10">Traditional therapy tries to fit you into diagnostic categories. ARCHII does the opposite — we map your actual emotional experiences and align our approach with your personality and life circumstances.</span>
          </p>
        </div>
        
        {/* Enhanced journey visualization with narrative-driven connection */}
        <div className="relative my-20 md:my-24 lg:my-32">
          {/* Vertical timeline with progress indicator */}
          <div className="absolute left-8 md:left-1/2 top-0 bottom-0 w-0.5 bg-[var(--color-selection)]/10"></div>
          <div 
            ref={progressLineRef}
            className="progress-line transition-all duration-1000 ease-out"
          ></div>
          
          {/* Journey steps with enhanced storytelling */}
          <div className="relative">
            {journeySteps.map((step, index) => (
              <div 
                key={index}
                className={`relative flex flex-col ${index % 2 === 0 ? 'md:flex-row' : 'md:flex-row-reverse'} items-start gap-6 md:gap-12 lg:gap-16 mb-24 md:mb-32 last:mb-0 journey-step group`}
                data-step={index}
              >
                {/* Step marker with enhanced visual */}
                <div className="relative z-20 flex-shrink-0 md:mt-0 flex md:block">
                  {/* Mobile timeline connector */}
                  <div className="h-full w-0.5 absolute top-0 bottom-0 bg-[var(--color-selection)]/10 left-8 md:hidden"></div>
                  
                  {/* Step marker that connects to the timeline */}
                  <div className="relative md:absolute md:left-1/2 md:-translate-x-1/2">
                    <div 
                      className="w-16 h-16 md:w-20 md:h-20 rounded-full bg-[var(--color-backgroundDarker)]/70 flex items-center justify-center border-2 transition-all duration-500 ease-out group-hover:scale-105 shadow-lg z-10"
                      style={{
                        borderColor: step.color,
                      }}
                    >
                      {/* Step number with enhanced typography */}
                      <div className="absolute inset-0 flex items-center justify-center">
                        <span className="relative flex items-center justify-center text-xl md:text-2xl font-bold translate-y-px" style={{ color: step.color, width: '100%', height: '100%', display: 'flex' }}>{step.icon}</span>
                      </div>
                  
                      {/* Subtle pulse effect on hover */}
                      <div 
                        className="absolute inset-[-2px] rounded-full opacity-0 group-hover:opacity-100 transition-opacity duration-700"
                        style={{
                          boxShadow: `0 0 15px ${step.color}40, inset 0 0 3px ${step.color}30`,
                        }}
                      ></div>
                    </div>
                    
                    {/* Progress indicator dot */}
                    <div 
                      className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-3 h-3 rounded-full transition-all duration-500 ease-out"
                      style={{
                        backgroundColor: step.color,
                        boxShadow: `0 0 10px ${step.color}`,
                        opacity: 0.7,
                        transform: "translate(-50%, -50%) scale(0.6)",
                      }}
                    ></div>
                  </div>
                  
                  {/* Step visualization - mobile only */}
                  <div className="ml-6 md:hidden w-32 h-32 flex-shrink-0 relative">
                    {renderVisual(step.visual)}
                  </div>
                </div>
                
                {/* Step content with enhanced visual storytelling */}
                <div className="flex-1 md:w-[calc(50%-60px)] md:max-w-[450px]">
                  <div 
                    className="p-6 md:p-8 rounded-xl relative overflow-hidden transition-all duration-500 ease-out md:hover:-translate-y-1 hover:shadow-[0_15px_30px_rgba(0,0,0,0.1),0_0_10px_rgba(187,154,247,0.03)]"
                    style={{
                      background: 'var(--color-backgroundDarker)/60',
                      backdropFilter: 'blur(10px)',
                      borderLeft: `1px solid ${step.color}20`,
                      borderTop: `1px solid ${step.color}15`,
                      borderRight: `1px solid transparent`,
                      borderBottom: `1px solid transparent`,
                    }}
                  >
                    {/* Subtle background gradient */}
                    <div 
                      className="absolute inset-0 opacity-5 transition-opacity duration-500 group-hover:opacity-10"
                      style={{
                        background: `linear-gradient(135deg, ${step.color}40, transparent)`,
                      }}
                    ></div>
                    
                    {/* Content with enhanced typography */}
                    <h3 
                      className="text-2xl md:text-2xl mb-4 md:mb-5 relative inline-block font-medium transition-all duration-500 ease-out group-hover:translate-x-1"
                      style={{
                        color: step.color,
                      }}
                    >
                      {step.title}
                      <span 
                        className="absolute bottom-[-5px] left-0 w-[50px] h-[2px] transition-all duration-600 ease-out group-hover:w-full group-hover:shadow-[0_0_8px_rgba(187,154,247,0.2)]"
                        style={{
                          backgroundColor: step.color,
                        }}
                      ></span>
                    </h3>
                    
                    <p className="text-[var(--color-comment)] leading-7 text-base md:text-lg relative z-10 transition-all duration-500 ease-out group-hover:text-[var(--color-foreground)]">
                      {step.description}
                    </p>
                    
                    {/* Enhanced visual appeal with subtle corner decorations */}
                    <div className="absolute top-0 right-0 w-8 h-8 opacity-20">
                      <div className="absolute top-0 right-0 w-full h-[1px] bg-[var(--color-selection)]/10"></div>
                      <div className="absolute top-0 right-0 h-full w-[1px] bg-[var(--color-selection)]/10"></div>
                    </div>
                    <div className="absolute bottom-0 left-0 w-8 h-8 opacity-20">
                      <div className="absolute bottom-0 left-0 w-full h-[1px] bg-[var(--color-selection)]/10"></div>
                      <div className="absolute bottom-0 left-0 h-full w-[1px] bg-[var(--color-selection)]/10"></div>
                    </div>
                  </div>
                </div>
                
                {/* Step visualization - desktop only */}
                <div className="hidden md:block w-[calc(50%-60px)] md:max-w-[450px] md:h-[200px]">
                  {renderVisual(step.visual)}
                </div>
              </div>
            ))}
            
            {/* Journey continuation symbol */}
            <div className="w-full flex justify-center mt-20">
              <div className="relative w-16 h-16 rounded-full border border-[var(--color-selection)]/30 flex items-center justify-center pulse">
                <div className="text-[var(--color-foreground)]/80 text-2xl">∞</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      {/* Enhanced transition to next section */}
      <div className="absolute bottom-0 left-0 w-full h-[30%] bg-gradient-to-b from-transparent to-[var(--color-background)] pointer-events-none"></div>
      
      {/* Journey-specific animations */}
      <style jsx>{`
        .journey-step {
          opacity: 0.7;
          transition: opacity 0.5s ease-out;
        }
        
        .journey-step-active {
          opacity: 1;
        }
        
        .journey-step-active [style*="border-color"] {
          transform: scale(1.1);
          box-shadow: 0 0 20px var(--color-backgroundLighter);
        }
      `}</style>
    </section>
  );
}

// Approach Section (replacing Results Section)
function ResultsSection({ isLowPerformanceDevice, colors, colorProgress }) {
  const [activeCardIndex, setActiveCardIndex] = useState(null);
  const cardsRef = useRef([]);
  
  // Approach data - focusing on key benefits rather than statistics
  const approachData = [
    {
      title: "Real Emotional Data",
      description: "We measure what matters — your actual emotional experiences, not just diagnostic symptoms.",
      details: "Rather than tracking generic clinical measures, ARCHII captures the full spectrum of your emotional life across contexts. This gives us a genuine understanding of your emotional patterns that goes beyond symptom checklists, allowing for truly personalized support.",
      color: "var(--color-magenta)",
      icon: "UnderstandingIcon",
      illustration: "understanding"
    },
    {
      title: "Personality-Matched Therapy",
      description: "Therapy adapted to how you think, feel and process information — not a one-size-fits-all approach.",
      details: "ARCHII aligns its therapeutic style with your personality traits. Analytical thinkers receive structured, evidence-based approaches. Emotional processors get validation-focused support. Creative minds benefit from metaphor and visualization. Your therapy matches who you are.",
      color: "var(--color-blue)",
      icon: "GrowthIcon",
      illustration: "growth"
    },
    {
      title: "Behavior Change That Sticks",
      description: "Practical strategies that work with your natural tendencies rather than fighting against them.",
      details: "We analyze the connection between your emotions, thoughts, and behaviors to identify effective change strategies. Instead of generic advice, ARCHII helps you develop actionable steps aligned with your natural strengths and tendencies, making sustainable progress possible.",
      color: "var(--color-green)",
      icon: "InsightsIcon",
      illustration: "insights"
    },
    {
      title: "Life-Stage Awareness",
      description: "Support that understands the specific challenges of your current life circumstances.",
      details: "Whether you're dealing with career transitions, relationship changes, parenting challenges, or major life shifts, ARCHII recognizes the context of your current life stage. This awareness ensures that therapeutic guidance is relevant to your actual situation, not generic advice.",
      color: "var(--color-cyan)",
      icon: "TransformationIcon",
      illustration: "transformation"
    }
  ];

  // Render approach icon with color transition effect
  const renderIcon = (iconName, color) => {
    switch(iconName) {
      case 'UnderstandingIcon':
        return (
          <svg viewBox="0 0 24 24" className="w-6 h-6 color-transition" fill="none" stroke={color} strokeWidth="2">
            <path d="M14 9a2 2 0 1 1-4 0 2 2 0 0 1 4 0z" />
            <path d="M12 2a10 10 0 1 0 0 20 10 10 0 0 0 0-20z" />
            <path d="M12 14c-2.21 0-4 1.79-4 4" />
          </svg>
        );
      case 'GrowthIcon':
        return (
          <svg viewBox="0 0 24 24" className="w-6 h-6 color-transition" fill="none" stroke={color} strokeWidth="2">
            <path d="M12 2L12 22" />
            <path d="M18 7L12 2 6 7" />
            <path d="M4 12H20" />
            <path d="M3 17H21" />
          </svg>
        );
      case 'InsightsIcon':
        return (
          <svg viewBox="0 0 24 24" className="w-6 h-6 color-transition" fill="none" stroke={color} strokeWidth="2">
            <path d="M21 12.79A9 9 0 1 1 11.21 3 7 7 0 0 0 21 12.79z" />
            <circle cx="12" cy="12" r="2" />
            <path d="M12 8v1" />
            <path d="M12 15v1" />
            <path d="M16 12h-1" />
            <path d="M9 12H8" />
          </svg>
        );
      case 'TransformationIcon':
        return (
          <svg viewBox="0 0 24 24" className="w-6 h-6 color-transition" fill="none" stroke={color} strokeWidth="2">
            <path d="M20.84 4.61a5.5 5.5 0 0 0-7.78 0L12 5.67l-1.06-1.06a5.5 5.5 0 0 0-7.78 7.78l1.06 1.06L12 21.23l7.78-7.78 1.06-1.06a5.5 5.5 0 0 0 0-7.78z" />
          </svg>
        );
      default:
        return (
          <svg viewBox="0 0 24 24" className="w-6 h-6 color-transition" fill="none" stroke={color} strokeWidth="2">
            <circle cx="12" cy="12" r="10" />
          </svg>
        );
    }
  };
  
  // Render approach illustrations
  const renderIllustration = (type, color) => {
    switch(type) {
      case 'understanding':
        return (
          <div className="relative h-32 w-full">
            <svg viewBox="0 0 200 100" className="w-full h-full">
              <defs>
                <linearGradient id="understanding-grad" x1="0%" y1="0%" x2="100%" y2="100%">
                  <stop offset="0%" stopColor={color} stopOpacity="0.6" />
                  <stop offset="100%" stopColor={color} stopOpacity="0.2" />
                </linearGradient>
              </defs>
              
              {/* Brain mapping visualization */}
              <circle cx="100" cy="50" r="30" fill="none" stroke={color} strokeWidth="1" strokeDasharray="3,3" className="animate-rotate-slow" />
              <circle cx="100" cy="50" r="15" fill={`url(#understanding-grad)`} className="pulse" />
              
              {/* Neural connections */}
              {[...Array(5)].map((_, i) => {
                const angle = (i / 5) * Math.PI * 2;
                const radius = 30;
                const x = 100 + Math.cos(angle) * radius;
                const y = 50 + Math.sin(angle) * radius;
                
                return (
                  <g key={i} className="opacity-0 animate-fade-in" style={{animationDelay: `${i * 0.2 + 0.5}s`, animationFillMode: 'forwards'}}>
                    <circle cx={x} cy={y} r="4" fill={color} fillOpacity="0.4" />
                    <line 
                      x1="100" 
                      y1="50" 
                      x2={x} 
                      y2={y} 
                      stroke={color} 
                      strokeWidth="1" 
                      strokeDasharray="4,2" 
                      strokeOpacity="0.5" 
                    />
                    
                    {/* Secondary connections */}
                    {[...Array(2)].map((_, j) => {
                      const angle2 = angle + (j * 0.5 - 0.25) * Math.PI;
                      const radius2 = 20 + Math.random() * 30;
                      const x2 = x + Math.cos(angle2) * 10;
                      const y2 = y + Math.sin(angle2) * 10;
                      
                      return (
                        <g key={`${i}-${j}`} className="opacity-0 animate-fade-in" style={{animationDelay: `${i * 0.2 + j * 0.1 + 0.7}s`, animationFillMode: 'forwards'}}>
                          <circle cx={x2} cy={y2} r="2" fill={color} fillOpacity="0.3" />
                          <line 
                            x1={x} 
                            y1={y} 
                            x2={x2} 
                            y2={y2} 
                            stroke={color} 
                            strokeWidth="0.5" 
                            strokeDasharray="2,2" 
                            strokeOpacity="0.3" 
                          />
                        </g>
                      );
                    })}
                  </g>
                );
              })}
            </svg>
          </div>
        );
      case 'growth':
        return (
          <div className="relative h-32 w-full">
            <svg viewBox="0 0 200 100" className="w-full h-full">
              <defs>
                <linearGradient id="growth-grad" x1="0%" y1="0%" x2="100%" y2="100%">
                  <stop offset="0%" stopColor={color} stopOpacity="0.6" />
                  <stop offset="100%" stopColor={color} stopOpacity="0.2" />
                </linearGradient>
              </defs>
              
              {/* Growth curve */}
              <path 
                d="M20,80 C40,80 40,60 60,60 C80,60 80,40 100,40 C120,40 120,20 140,20 C160,20 180,20 180,20" 
                fill="none" 
                stroke={color} 
                strokeWidth="2"
                strokeDasharray="180"
                strokeDashoffset="180"
                className="animate-draw"
                style={{animationDuration: '2s'}}
              />
              
              {/* Growth points */}
              {[
                {x: 20, y: 80},
                {x: 60, y: 60},
                {x: 100, y: 40},
                {x: 140, y: 20},
                {x: 180, y: 20}
              ].map((point, i) => (
                <circle 
                  key={i}
                  cx={point.x} 
                  cy={point.y} 
                  r={i === 0 ? 3 : i === 4 ? 5 : 4} 
                  fill={i === 4 ? `url(#growth-grad)` : color}
                  opacity="0"
                  className="animate-fade-in pulse"
                  style={{
                    animationDelay: `${i * 0.3 + 0.5}s`,
                    animationFillMode: 'forwards'
                  }}
                />
              ))}
              
              {/* Level labels */}
              {[
                {x: 20, y: 80, label: "Start"},
                {x: 100, y: 40, label: "Growth"},
                {x: 180, y: 20, label: "Evolution"}
              ].map((point, i) => (
                <g 
                  key={i}
                  opacity="0"
                  className="animate-fade-in"
                  style={{
                    animationDelay: `${i * 0.5 + 1.5}s`,
                    animationFillMode: 'forwards'
                  }}
                >
                  <text 
                    x={point.x} 
                    y={point.y + 15} 
                    fill={color} 
                    fontSize="8" 
                    textAnchor="middle" 
                    opacity="0.7"
                  >
                    {point.label}
                  </text>
                </g>
              ))}
            </svg>
          </div>
        );
      case 'insights':
        return (
          <div className="relative h-32 w-full">
            <svg viewBox="0 0 200 100" className="w-full h-full">
              <defs>
                <linearGradient id="insights-grad" x1="0%" y1="0%" x2="100%" y2="100%">
                  <stop offset="0%" stopColor={color} stopOpacity="0.6" />
                  <stop offset="100%" stopColor={color} stopOpacity="0.2" />
                </linearGradient>
              </defs>
              
              {/* Central insight node */}
              <circle cx="100" cy="50" r="12" fill={`url(#insights-grad)`} className="pulse" />
              <circle cx="100" cy="50" r="6" fill={color} fillOpacity="0.8" />
              
              {/* Connected nodes */}
              {[
                {angle: 0, label: "Emotions"},
                {angle: Math.PI * 0.4, label: "Patterns"},
                {angle: Math.PI * 0.8, label: "Memories"},
                {angle: Math.PI * 1.2, label: "Behaviors"},
                {angle: Math.PI * 1.6, label: "Thoughts"}
              ].map((node, i) => {
                const distance = 30 + Math.random() * 10;
                const x = 100 + Math.cos(node.angle) * distance;
                const y = 50 + Math.sin(node.angle) * distance;
                
                return (
                  <g key={i} className="opacity-0 animate-fade-in" style={{animationDelay: `${i * 0.2 + 0.5}s`, animationFillMode: 'forwards'}}>
                    <circle cx={x} cy={y} r="5" fill={color} fillOpacity="0.4" />
                    <line 
                      x1="100" 
                      y1="50" 
                      x2={x} 
                      y2={y} 
                      stroke={color} 
                      strokeWidth="1" 
                      strokeDasharray="180" 
                      strokeDashoffset="180" 
                      strokeOpacity="0.6" 
                      className="animate-draw"
                      style={{animationDuration: '1.5s', animationDelay: `${i * 0.1}s`}}
                    />
                    <text 
                      x={x} 
                      y={y < 50 ? y - 8 : y + 12} 
                      fill={color} 
                      fontSize="7" 
                      textAnchor="middle" 
                      opacity="0.8"
                      className="opacity-0 animate-fade-in" 
                      style={{animationDelay: `${i * 0.2 + 1}s`, animationFillMode: 'forwards'}}
                    >
                      {node.label}
                    </text>
                  </g>
                );
              })}
              
              {/* Highlight effect */}
              <circle 
                cx="100" 
                cy="50" 
                r="20" 
                fill="none" 
                stroke={color} 
                strokeWidth="1"
                strokeOpacity="0.3"
                className="pulse"
                style={{animationDuration: '3s'}}
              />
            </svg>
          </div>
        );
      case 'transformation':
        return (
          <div className="relative h-32 w-full">
            <svg viewBox="0 0 200 100" className="w-full h-full">
              <defs>
                <linearGradient id="transform-grad" x1="0%" y1="0%" x2="100%" y2="100%">
                  <stop offset="0%" stopColor={color} stopOpacity="0.6" />
                  <stop offset="100%" stopColor={color} stopOpacity="0.2" />
                </linearGradient>
              </defs>
              
              {/* Transformation path */}
              <path 
                d="M20,50 C60,80 100,20 140,50 C160,65 180,50 180,50" 
                fill="none" 
                stroke={color} 
                strokeWidth="1.5"
                strokeDasharray="220"
                strokeDashoffset="220"
                strokeOpacity="0.5"
                className="animate-draw"
                style={{animationDuration: '2s'}}
              />
              
              {/* Starting point */}
              <circle cx="20" cy="50" r="6" fill={color} fillOpacity="0.4" />
              
              {/* Ending point with glow effect */}
              <circle cx="180" cy="50" r="8" fill={`url(#transform-grad)`} className="pulse" />
              
              {/* Transformation stages */}
              {[
                {x: 60, y: 80, size: 4, delay: 0.8},
                {x: 100, y: 20, size: 5, delay: 1.2},
                {x: 140, y: 50, size: 6, delay: 1.6},
              ].map((point, i) => (
                <circle 
                  key={i}
                  cx={point.x} 
                  cy={point.y} 
                  r={point.size} 
                  fill={color}
                  fillOpacity="0"
                  className="animate-fade-pulse"
                  style={{
                    animationDelay: `${point.delay}s`,
                    animationFillMode: 'forwards'
                  }}
                />
              ))}
              
              {/* Direction arrows */}
              <g className="opacity-0 animate-fade-in" style={{animationDelay: '2s', animationFillMode: 'forwards'}}>
                <path 
                  d="M170,45 L180,50 L170,55" 
                  fill="none" 
                  stroke={color} 
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
              
              {/* Labels */}
              <g className="opacity-0 animate-fade-in" style={{animationDelay: '2.2s', animationFillMode: 'forwards'}}>
                <text x="20" y="65" fill={color} fontSize="7" textAnchor="middle">Start</text>
                <text x="180" y="65" fill={color} fontSize="7" textAnchor="middle">Transformation</text>
              </g>
            </svg>
          </div>
        );
      default:
        return null;
    }
  };

  // Handle card interactions
  useEffect(() => {
    // Setup refs array
    cardsRef.current = cardsRef.current.slice(0, approachData.length);
    
    // Add hover effect listeners for desktop
    const handleMouseMove = (e, card, index) => {
      if (isLowPerformanceDevice) return;
      
      const rect = card.getBoundingClientRect();
      const x = e.clientX - rect.left;
      const y = e.clientY - rect.top;
      
      const centerX = rect.width / 2;
      const centerY = rect.height / 2;
      
      const tiltX = (y - centerY) / 20;
      const tiltY = (centerX - x) / 20;
      
      card.style.transform = `perspective(1000px) rotateX(${tiltX}deg) rotateY(${tiltY}deg) scale3d(1.02, 1.02, 1.02)`;
      
      // Add glow effect
      const intensity = 20;
      const glowX = ((x / rect.width) * 100) + '%';
      const glowY = ((y / rect.height) * 100) + '%';
      
      card.style.background = `radial-gradient(circle at ${glowX} ${glowY}, ${approachData[index].color}10, var(--color-backgroundDarker) 60%)`;
      card.style.boxShadow = `0 5px 15px rgba(0,0,0,0.2), 0 0 30px ${approachData[index].color}10`;
    };
    
    const handleMouseLeave = (card, index) => {
      if (isLowPerformanceDevice) return;
      
      card.style.transform = '';
      card.style.background = 'var(--color-backgroundDarker)/70';
      card.style.boxShadow = '';
    };
    
    // Apply to all cards
    cardsRef.current.forEach((card, index) => {
      if (!card) return;
      
      card.addEventListener('mousemove', (e) => handleMouseMove(e, card, index));
      card.addEventListener('mouseleave', () => handleMouseLeave(card, index));
    });
    
    return () => {
      cardsRef.current.forEach((card, index) => {
        if (!card) return;
        
        card.removeEventListener('mousemove', (e) => handleMouseMove(e, card, index));
        card.removeEventListener('mouseleave', () => handleMouseLeave(card, index));
      });
    };
  }, [isLowPerformanceDevice, approachData]);

  // Animation and scroll references
  const sectionRef = useRef(null);
  
  // Handle scroll-triggered animations
  useEffect(() => {
    const section = sectionRef.current;
    if (!section) return;
    
    // For mobile/low performance devices, make content visible immediately
    if (isLowPerformanceDevice) {
      section.classList.add('in-view');
      // Make cards visible
      cardsRef.current.forEach((card, index) => {
        if (card) {
          card.style.opacity = '1';
          card.style.transform = 'translateY(0)';
        }
      });
      return;
    }
    
    let ticking = false;
    
    const handleScroll = () => {
      if (!ticking) {
        requestAnimationFrame(() => {
          const section = sectionRef.current;
          if (!section) {
            ticking = false;
            return;
          }
          
          const sectionTop = section.getBoundingClientRect().top;
          const windowHeight = window.innerHeight;
          
          // Animate section elements when they come into view
          if (sectionTop < windowHeight * 0.75) {
            section.classList.add('in-view');
          }
          
          ticking = false;
        });
        
        ticking = true;
      }
    };
    
    window.addEventListener('scroll', handleScroll, { passive: true });
    handleScroll(); // Check on initial load
    
    return () => window.removeEventListener('scroll', handleScroll);
  }, [isLowPerformanceDevice]);

  return (
    <section 
      id="results" 
      ref={sectionRef}
      className="relative z-40 pt-32 md:pt-40 lg:pt-48 pb-24 md:pb-32 -mt-10 md:-mt-20 min-h-screen scroll-section"
    >
      {/* Background with subtle patterns */}
      <div className="absolute inset-0 bg-[var(--color-background)]">
        <div className="absolute inset-0 story-pattern opacity-[0.03]"></div>
        
        {!isLowPerformanceDevice && (
          <div className="absolute inset-0 overflow-hidden opacity-5">
            <svg width="100%" height="100%" viewBox="0 0 800 600" preserveAspectRatio="xMidYMid slice">
              {/* Abstract representation of connected concepts */}
              <g opacity="0.5">
                <circle cx="400" cy="300" r="150" fill="none" stroke="var(--color-blue)" strokeWidth="0.5" strokeDasharray="3,3" className="animate-rotate-slow" />
                <circle cx="400" cy="300" r="100" fill="none" stroke="var(--color-magenta)" strokeWidth="0.5" strokeDasharray="5,5" className="animate-rotate-slow" style={{animationDirection: 'reverse'}} />
                
                {/* Connections */}
                {[...Array(8)].map((_, i) => {
                  const angle = (i / 8) * Math.PI * 2;
                  const x1 = 400 + Math.cos(angle) * 100;
                  const y1 = 300 + Math.sin(angle) * 100;
                  const x2 = 400 + Math.cos(angle) * 150;
                  const y2 = 300 + Math.sin(angle) * 150;
                  
                  return (
                    <line 
                      key={i}
                      x1={x1} 
                      y1={y1} 
                      x2={x2} 
                      y2={y2} 
                      stroke="var(--color-selection)" 
                      strokeWidth="0.5" 
                      strokeDasharray="2,2"
                      opacity="0.3"
                    />
                  );
                })}
              </g>
            </svg>
          </div>
        )}
      </div>
      
      <div className="max-w-[1400px] mx-auto px-6 md:px-8 lg:px-16 relative z-10">
        {/* Section title */}
        <div className="mb-16 md:mb-20 lg:mb-24 relative story-element">
          <div className="flex items-center gap-2 mb-3">
            <div className="h-px flex-grow max-w-[100px] bg-gradient-to-r from-[var(--color-selection)]/0 to-[var(--color-selection)]/20"></div>
            <div className="inline-block px-3 py-1.5 rounded-full bg-[var(--color-selection)]/20 backdrop-blur-sm border border-[var(--color-selection)]/20 text-xs uppercase tracking-widest text-[var(--color-foreground)]/70">Our Approach</div>
            <div className="h-px flex-grow max-w-[100px] bg-gradient-to-l from-[var(--color-selection)]/0 to-[var(--color-selection)]/20"></div>
          </div>
          
          <h2 className="text-3xl md:text-4xl lg:text-5xl font-serif font-bold italic bg-gradient-to-r from-[var(--color-foreground)] to-[var(--color-comment)] text-transparent bg-clip-text mb-4 text-center">
            Beyond Traditional Therapy
          </h2>
          
          <div className="flex justify-center mb-6">
            <div className="h-1 w-24 rounded-full bg-gradient-to-r from-[var(--color-magenta)]/50 to-[var(--color-blue)]/50 transition-all duration-500 hover:w-32 hover:shadow-[0_0_10px_rgba(187,154,247,0.5)]"></div>
          </div>
          
          <p className="font-sans text-lg md:text-xl text-[var(--color-comment)] max-w-[800px] mx-auto text-center leading-relaxed relative">
            <span className="relative z-10">Our approach shifts the paradigm from treating symptoms to understanding your complete emotional story.</span>
          </p>
        </div>
        
        {/* Approach grid with enhanced interactions */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-10 lg:gap-12 story-element">
          {approachData.map((approach, index) => (
            <div 
              key={index}
              ref={el => cardsRef.current[index] = el}
              className="relative rounded-xl p-8 overflow-hidden transition-all duration-500 ease-out hover:shadow-lg opacity-0 animate-fade-up cursor-pointer"
              style={{
                background: 'var(--color-backgroundDarker)/70',
                backdropFilter: 'blur(10px)',
                borderLeft: `1px solid ${approach.color}20`,
                borderTop: `1px solid ${approach.color}15`,
                borderRight: `1px solid transparent`,
                borderBottom: `1px solid transparent`,
                animationDelay: `${index * 0.15 + 0.3}s`,
                animationFillMode: 'forwards',
                transform: 'translate3d(0, 0, 0)'
              }}
              onClick={() => setActiveCardIndex(activeCardIndex === index ? null : index)}
            >
              {/* Enhanced background effects */}
              <div 
                className="absolute inset-0 opacity-5 transition-opacity duration-500 hover:opacity-10"
                style={{
                  background: `radial-gradient(circle at center, ${approach.color}40, transparent)`,
                }}
              ></div>
              
              {/* Animated border effect */}
              <div className="absolute inset-0 overflow-hidden rounded-xl pointer-events-none">
                <div 
                  className="absolute top-0 left-0 w-full h-[1px] animate-border-flow"
                  style={{
                    background: `linear-gradient(90deg, transparent, ${approach.color}, transparent)`,
                    animationDuration: '3s',
                    animationDelay: `${index * 0.2}s`
                  }}
                ></div>
                <div 
                  className="absolute top-0 right-0 w-[1px] h-full animate-border-flow"
                  style={{
                    background: `linear-gradient(180deg, transparent, ${approach.color}, transparent)`,
                    animationDuration: '3s',
                    animationDelay: `${index * 0.2 + 0.1}s`
                  }}
                ></div>
                <div 
                  className="absolute bottom-0 left-0 w-full h-[1px] animate-border-flow"
                  style={{
                    background: `linear-gradient(90deg, transparent, ${approach.color}, transparent)`,
                    animationDuration: '3s',
                    animationDelay: `${index * 0.2 + 0.2}s`
                  }}
                ></div>
                <div 
                  className="absolute top-0 left-0 w-[1px] h-full animate-border-flow"
                  style={{
                    background: `linear-gradient(180deg, transparent, ${approach.color}, transparent)`,
                    animationDuration: '3s',
                    animationDelay: `${index * 0.2 + 0.3}s`
                  }}
                ></div>
              </div>
              
              {/* Approach content */}
              <div className="flex flex-col h-full relative z-10">
                <div className="flex items-start gap-4 mb-4">
                  <div 
                    className="flex-shrink-0 w-16 h-16 rounded-lg flex items-center justify-center shadow-inner group"
                    style={{
                      background: `${approach.color}15`,
                      border: `1px solid ${approach.color}30`,
                    }}
                  >
                    {renderIcon(approach.icon, approach.color)}
                    
                    {/* Icon glow effect */}
                    <div 
                      className="absolute inset-0 rounded-lg opacity-0 group-hover:opacity-100 transition-opacity duration-700"
                      style={{
                        boxShadow: `0 0 20px ${approach.color}30, inset 0 0 5px ${approach.color}20`,
                      }}
                    ></div>
                  </div>
                  
                  <div>
                    <h3 
                      className="text-2xl font-bold mb-2 relative"
                      style={{ color: approach.color }}
                    >
                      {approach.title}
                      <span 
                        className="absolute -bottom-1 left-0 w-10 h-[2px] transition-all duration-500 group-hover:w-full"
                        style={{background: `linear-gradient(to right, ${approach.color}, transparent)`}}
                      ></span>
                    </h3>
                  </div>
                </div>
                
                <p className="text-[var(--color-comment)] text-base mb-4 transition-colors duration-500 hover:text-[var(--color-foreground)]/80">
                  {approach.description}
                </p>
                
                {/* Interactive illustration */}
                <div className="mt-2 mb-2 overflow-hidden h-32 transition-all duration-500">
                  {renderIllustration(approach.illustration, approach.color)}
                </div>
                
                {/* Expandable details */}
                <div 
                  className={`overflow-hidden transition-all duration-500 ease-in-out ${
                    activeCardIndex === index ? 'max-h-[200px] opacity-100 mt-4' : 'max-h-0 opacity-0'
                  }`}
                >
                  <div className={`p-4 rounded-lg bg-[var(--color-backgroundLighter)]/10 border-l-2`} style={{borderColor: approach.color}}>
                    <p className="text-sm text-[var(--color-foreground)]/90">{approach.details}</p>
                  </div>
                </div>
                
                {/* Read more indicator */}
                <div className="mt-auto pt-4 flex justify-end">
                  <div 
                    className="flex items-center gap-1 text-xs text-[var(--color-comment)] transition-colors duration-300 hover:text-[var(--color-foreground)]"
                    style={{ color: activeCardIndex === index ? approach.color : 'var(--color-comment)' }}
                  >
                    <span>{activeCardIndex === index ? 'Show less' : 'Learn more'}</span>
                    <svg 
                      className={`w-4 h-4 transition-transform duration-300 ${activeCardIndex === index ? 'rotate-180' : ''}`} 
                      fill="none" 
                      viewBox="0 0 24 24" 
                      stroke="currentColor"
                    >
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
                    </svg>
                  </div>
                </div>
                
                {/* Visual corner accents with animation */}
                <div className="absolute top-0 right-0 w-8 h-8 opacity-20">
                  <div className="absolute top-0 right-0 w-full h-[1px] bg-[var(--color-selection)]/10"></div>
                  <div className="absolute top-0 right-0 h-full w-[1px] bg-[var(--color-selection)]/10"></div>
                </div>
                <div className="absolute bottom-0 left-0 w-8 h-8 opacity-20">
                  <div className="absolute bottom-0 left-0 w-full h-[1px] bg-[var(--color-selection)]/10"></div>
                  <div className="absolute bottom-0 left-0 h-full w-[1px] bg-[var(--color-selection)]/10"></div>
                </div>
              </div>
            </div>
          ))}
        </div>
        
        {/* Enhanced comparison box with animations */}
        <div className="mt-16 md:mt-20 relative story-element">
          <div className="max-w-[900px] mx-auto p-8 rounded-xl border border-[var(--color-selection)]/10 bg-[var(--color-backgroundDarker)]/30 backdrop-blur-sm opacity-0 animate-fade-in" style={{animationDelay: "1s", animationFillMode: "forwards"}}>
            <div className="absolute -top-3 -left-3 w-6 h-6 border-t border-l border-[var(--color-selection)]/20"></div>
            <div className="absolute -bottom-3 -right-3 w-6 h-6 border-b border-r border-[var(--color-selection)]/20"></div>
            
            {/* Animated section title */}
            <div className="relative mb-6 text-center">
              <h4 className="text-xl md:text-2xl font-medium inline-block relative">
                Traditional vs ARCHII Approach
                <span className="absolute -bottom-2 left-0 w-full h-[1px] bg-gradient-to-r from-[var(--color-magenta)]/30 via-[var(--color-blue)]/30 to-[var(--color-magenta)]/30"></span>
              </h4>
            </div>
            
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div className="p-5 rounded-lg bg-[var(--color-selection)]/5 border border-[var(--color-selection)]/10 transition-all duration-500 hover:shadow-lg hover:-translate-y-1">
                <div className="text-base text-[var(--color-red)]/80 mb-3 font-medium text-center relative">
                  <span>Traditional Therapy</span>
                  <span className="absolute -bottom-1 left-1/2 -translate-x-1/2 w-10 h-[1px] bg-[var(--color-red)]/30"></span>
                </div>
                <ul className="space-y-3">
                  {[
                    "Fits you into diagnostic boxes", 
                    "One-size-fits-all treatment",
                    "Static approach that rarely updates", 
                    "Focuses on reducing symptoms",
                    "Ignores your personality differences"
                  ].map((item, i) => (
                    <li key={i} className="flex items-center gap-2 text-sm text-[var(--color-comment)] opacity-0 animate-fade-up" style={{animationDelay: `${i * 0.1 + 1.2}s`, animationFillMode: 'forwards'}}>
                      <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="text-[var(--color-red)]/70 flex-shrink-0">
                        <path d="M18 6L6 18M6 6L18 18" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                      </svg>
                      <span>{item}</span>
                    </li>
                  ))}
                </ul>
              </div>
              
              <div className="p-5 rounded-lg bg-[var(--color-backgroundLighter)]/10 border border-[var(--color-selection)]/10 transition-all duration-500 hover:shadow-lg hover:-translate-y-1">
                <div className="text-base text-[var(--color-cyan)]/80 mb-3 font-medium text-center relative">
                  <span>ARCHII Approach</span>
                  <span className="absolute -bottom-1 left-1/2 -translate-x-1/2 w-10 h-[1px] bg-[var(--color-cyan)]/30"></span>
                </div>
                <ul className="space-y-3">
                  {[
                    "Maps your actual emotional life", 
                    "Adapts to your personality type",
                    "Evolves as your needs change", 
                    "Builds on your natural strengths",
                    "Aligns with your life circumstances"
                  ].map((item, i) => (
                    <li key={i} className="flex items-center gap-2 text-sm text-[var(--color-foreground)]/90 opacity-0 animate-fade-up" style={{animationDelay: `${i * 0.1 + 1.4}s`, animationFillMode: 'forwards'}}>
                      <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="text-[var(--color-cyan)]/70 flex-shrink-0">
                        <path d="M5 12L10 17L20 7" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                      </svg>
                      <span>{item}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            
            <div className="mt-6 text-center opacity-0 animate-fade-in" style={{animationDelay: "2s", animationFillMode: "forwards"}}>
              <div className="inline-flex items-center gap-2 text-[var(--color-cyan)] hover:text-[var(--color-cyan)]/80 transition-colors cursor-pointer">
                <span className="underline text-sm relative group">
                  Learn more about our methodology
                  <span className="absolute bottom-0 left-0 w-0 h-[0.5px] bg-[var(--color-cyan)] transition-all duration-300 group-hover:w-full"></span>
                </span>
                <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="group-hover:translate-x-1 transition-transform duration-300">
                  <path d="M7 17L17 7M17 7H7M17 7V17" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
              </div>
            </div>
          </div>
        </div>
        
        {/* Research Foundation with animated backgrounds */}
        <div className="mt-24 md:mt-32 relative story-element">
          <div className="mb-12 text-center">
            <h3 className="text-2xl md:text-3xl font-medium mb-3 relative inline-block">
              Research Foundation
              <div className="absolute -bottom-2 left-0 w-full h-[1px] bg-gradient-to-r from-transparent via-[var(--color-selection)]/40 to-transparent"></div>
            </h3>
            <p className="text-[var(--color-comment)] text-lg max-w-[700px] mx-auto">
              Our emotion-first approach is built on cutting-edge science in affective neuroscience and personality research
            </p>
          </div>
          
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6 md:gap-8">
            {/* Research pillars with animations */}
            {[
              {
                title: "Emotion Science", 
                description: "Tracking emotional patterns in real time to understand your unique emotional signature rather than diagnostic labels",
                color: "var(--color-magenta)"
              },
              {
                title: "Personality Alignment", 
                description: "Matching therapeutic approaches to your natural thinking style, whether analytical, relational, practical, or creative",
                color: "var(--color-blue)"
              },
              {
                title: "Behavioral Psychology", 
                description: "Translating emotional insights into concrete action steps that work with your natural tendencies and life circumstances",
                color: "var(--color-green)"
              }
            ].map((pillar, index) => (
              <div 
                key={index}
                className="p-6 rounded-xl relative overflow-hidden transition-all duration-500 ease-out hover:-translate-y-1 hover:shadow-lg opacity-0 animate-fade-up group"
                style={{
                  background: 'var(--color-backgroundDarker)/40',
                  backdropFilter: 'blur(5px)',
                  borderLeft: `1px solid ${pillar.color}15`,
                  borderTop: `1px solid ${pillar.color}10`,
                  animationDelay: `${index * 0.2 + 0.5}s`,
                  animationFillMode: 'forwards'
                }}
              >
                {/* Enhanced background animations */}
                <div 
                  className="absolute inset-0 opacity-0 group-hover:opacity-100 transition-opacity duration-700"
                  style={{
                    background: `radial-gradient(circle at center, ${pillar.color}15, transparent 70%)`,
                  }}
                ></div>
                
                <div 
                  className="w-12 h-12 rounded-full mb-4 flex items-center justify-center relative"
                  style={{
                    background: `${pillar.color}15`,
                    border: `1px solid ${pillar.color}20`,
                  }}
                >
                  {/* Animated orbit effect */}
                  <div 
                    className="absolute inset-[-5px] rounded-full border border-dashed animate-rotate-slow opacity-70"
                    style={{
                      borderColor: `${pillar.color}30`,
                      animationDuration: '20s'
                    }}
                  ></div>
                  
                  <div 
                    className="w-6 h-6 rounded-full pulse"
                    style={{
                      background: `${pillar.color}30`,
                    }}
                  ></div>
                </div>
                
                <h4 
                  className="text-xl font-medium mb-2 relative inline-block"
                  style={{ color: pillar.color }}
                >
                  {pillar.title}
                  <span 
                    className="absolute -bottom-1 left-0 w-10 h-[1px] transition-all duration-500 group-hover:w-full"
                    style={{background: pillar.color}}
                  ></span>
                </h4>
                
                <p className="text-[var(--color-comment)] text-sm transition-colors duration-300 group-hover:text-[var(--color-foreground)]/80">
                  {pillar.description}
                </p>
                
                {/* Corner accents */}
                <div className="absolute top-0 right-0 w-6 h-6 opacity-20">
                  <div className="absolute top-0 right-0 w-full h-[1px] bg-[var(--color-selection)]/10"></div>
                  <div className="absolute top-0 right-0 h-full w-[1px] bg-[var(--color-selection)]/10"></div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      
      {/* Enhanced transition to next section */}
      <div className="absolute bottom-0 left-0 w-full h-[30%] bg-gradient-to-b from-transparent to-[var(--color-background)] pointer-events-none"></div>
      
      {/* Enhanced section-specific animations */}
      <style jsx>{`
        @keyframes rotate-slow {
          from { transform: rotate(0deg); }
          to { transform: rotate(360deg); }
        }
        
        .animate-rotate-slow {
          animation: rotate-slow 60s linear infinite;
        }
        
        @keyframes fade-up {
          from {
            opacity: 0;
            transform: translateY(20px);
          }
          to {
            opacity: 1;
            transform: translateY(0);
          }
        }
        
        .animate-fade-up {
          animation: fade-up 0.8s forwards;
        }
        
        @keyframes fade-in {
          from { opacity: 0; }
          to { opacity: 1; }
        }
        
        .animate-fade-in {
          animation: fade-in 0.8s forwards;
        }
        
        @keyframes border-flow {
          0% { transform: translateX(-100%); }
          50% { transform: translateX(0); }
          100% { transform: translateX(100%); }
        }
        
        .animate-border-flow {
          animation: border-flow 3s ease-in-out infinite;
        }
        
        @keyframes draw {
          to { stroke-dashoffset: 0; }
        }
        
        .animate-draw {
          animation: draw 2s ease-out forwards;
        }
        
        @keyframes fade-pulse {
          0% { fill-opacity: 0; transform: scale(0); }
          50% { fill-opacity: 0.7; transform: scale(1.2); }
          100% { fill-opacity: 0.4; transform: scale(1); }
        }
        
        .animate-fade-pulse {
          animation: fade-pulse 1s ease-out forwards;
        }
      `}</style>
    </section>
  );
}

// CTA Section
function CTASection({ isLowPerformanceDevice, colors, colorProgress }) {
  const [showForm, setShowForm] = useState(false);
  const formRef = useRef(null);
  const [formLoading, setFormLoading] = useState(true);
  const sectionRef = useRef(null);
  
  // Ensure visibility on mobile devices
  useEffect(() => {
    const section = sectionRef.current;
    if (section && isLowPerformanceDevice) {
      section.classList.add('in-view');
      
      // Make story elements visible
      section.querySelectorAll('.story-element').forEach(el => {
        el.style.opacity = '1';
        el.style.transform = 'translateY(0)';
      });
    }
  }, [isLowPerformanceDevice]);
  
  const toggleForm = (e) => {
    e.preventDefault();
    setShowForm(!showForm);
    if (!showForm) {
      setFormLoading(true);
    }
  };

  // Close form when clicking outside
  useEffect(() => {
    if (showForm) {
      const handleClickOutside = (e) => {
        if (formRef.current && !formRef.current.contains(e.target) && !e.target.closest('.cta-waitlist-button')) {
          setShowForm(false);
        }
      };
      
      document.addEventListener('mousedown', handleClickOutside);
      return () => document.removeEventListener('mousedown', handleClickOutside);
    }
  }, [showForm]);

  const handleIframeLoad = () => {
    setFormLoading(false);
  };

  const handleIframeError = () => {
    setFormLoading(false);
    if (formRef.current) {
      const errorDiv = document.createElement('div');
      errorDiv.className = 'p-8 text-center';
      errorDiv.innerHTML = `
        <div class="text-[var(--color-red)] mb-4">Unable to load the form</div>
        <p class="mb-4">Please use the direct link instead:</p>
        
        <a href="https://airtable.com/app4R7YLW3rB68X6E/pagkPgY1d5nWZrwea/form" target="_blank" 
          rel="noopener noreferrer"
          class="px-4 py-2 bg-[var(--color-selection)]/40 hover:bg-[var(--color-selection)]/60 rounded-lg transition-colors inline-block">
          Open Airtable Form
        </a>
      `;
      
      // Find and replace the iframe
      const iframe = formRef.current.querySelector('iframe');
      if (iframe && iframe.parentNode) {
        iframe.parentNode.replaceChild(errorDiv, iframe);
      }
    }
  };
  
  return (
    <section 
      id="cta" 
      ref={sectionRef}
      className="relative z-50 flex items-center min-h-screen pt-32 md:pt-40 lg:pt-48 pb-24 md:pb-32 -mt-10 md:-mt-20 scroll-section"
    >
      {/* Enhanced background */}
      <div className="absolute inset-0 bg-gradient-to-b from-[var(--color-background)] to-[var(--color-backgroundDarker)]"></div>
      <div className="absolute inset-0 story-pattern opacity-[0.05]"></div>
      
      {/* Visual elements */}
      <div className="absolute inset-0 overflow-hidden">
        {!isLowPerformanceDevice && (
          <div className="absolute inset-0 overflow-hidden opacity-10">
            <svg width="100%" height="100%" viewBox="0 0 800 600" preserveAspectRatio="xMidYMid slice">
              {/* Path representing the journey */}
              <path 
                d="M200,500 Q300,400 400,450 Q500,500 600,400" 
                fill="none" 
                stroke="var(--color-blue)" 
                strokeWidth="1" 
                strokeDasharray="5,5"
                opacity="0.4"
                className="animate-dash-flow"
              />
              
              {/* Journey nodes */}
              <circle cx="200" cy="500" r="5" fill="var(--color-magenta)" opacity="0.5" className="pulse" />
              <circle cx="400" cy="450" r="8" fill="var(--color-blue)" opacity="0.5" className="pulse" style={{animationDelay: '1s'}} />
              <circle cx="600" cy="400" r="5" fill="var(--color-cyan)" opacity="0.5" className="pulse" style={{animationDelay: '2s'}} />
              
              {/* Future pathways */}
              <path 
                d="M600,400 Q650,350 700,300" 
                fill="none" 
                stroke="var(--color-cyan)" 
                strokeWidth="1" 
                strokeDasharray="3,3"
                opacity="0.3"
                className="animate-dash-flow"
                style={{animationDelay: '3s'}}
              />
              
              <path 
                d="M600,400 Q650,380 700,350" 
                fill="none" 
                stroke="var(--color-cyan)" 
                strokeWidth="1" 
                strokeDasharray="3,3"
                opacity="0.3"
                className="animate-dash-flow"
                style={{animationDelay: '3.2s'}}
              />
              
              <path 
                d="M600,400 Q650,410 700,400" 
                fill="none" 
                stroke="var(--color-cyan)" 
                strokeWidth="1" 
                strokeDasharray="3,3"
                opacity="0.3"
                className="animate-dash-flow"
                style={{animationDelay: '3.4s'}}
              />
            </svg>
          </div>
        )}
      </div>
      
      <div className="max-w-[900px] mx-auto px-6 md:px-8 lg:px-16 text-center relative z-10">
        <div className="story-element" style={{"--delay": "0.2s"}}>
          {/* CTA badge */}
          <div className="inline-block mb-3 px-3 py-1.5 rounded-full bg-[var(--color-selection)]/20 backdrop-blur-sm border border-[var(--color-selection)]/20 text-xs uppercase tracking-widest text-[var(--color-foreground)]/70">Begin Your Journey</div>
          
          {/* CTA headline */}
          <h2 className="text-4xl md:text-5xl lg:text-6xl mb-6 md:mb-8 font-bold">
            Therapy That Actually Gets You
          </h2>
        </div>
        
        <div className="story-element max-w-[700px] mx-auto" style={{"--delay": "0.4s"}}>
          {/* CTA description */}
          <p className="text-base md:text-lg lg:text-xl text-[var(--color-comment)] mb-8 md:mb-10 lg:mb-12 leading-relaxed relative">
            Experience therapy that adapts to who you really are. ARCHII syncs with your emotional patterns, personality, and life stage to deliver support that evolves with you — not generic advice based on diagnostic labels.
          </p>
        </div>
        
        <div className="story-element" style={{"--delay": "0.6s"}}>
          <div className="relative">
            {/* CTA button */}
            <a 
              href="https://airtable.com/app4R7YLW3rB68X6E/pagkPgY1d5nWZrwea/form" 
              target="_blank"
              rel="noopener noreferrer"
              className="group relative overflow-hidden px-10 py-5 rounded-lg text-lg bg-[var(--color-selection)]/50 border border-[var(--color-magenta)]/30 font-medium tracking-wide text-[var(--color-foreground)]/90 transition-all duration-500 hover:bg-[var(--color-selection)]/60 hover:border-[var(--color-magenta)]/40 hover:-translate-y-1 hover:shadow-[0_10px_30px_rgba(0,0,0,0.2),0_0_15px_rgba(187,154,247,0.05)] inline-block"
              data-cursor-text="Start"
            >
              <span className="relative z-10 flex items-center gap-3">
                <span>join waitlist</span>
              </span>
              
              <span className="absolute inset-0 translate-x-[-100%] group-hover:translate-x-[100%] transition-transform duration-1000 bg-gradient-to-r from-transparent via-[var(--color-foreground)]/5 to-transparent"></span>
              
              <span className="absolute inset-0 opacity-0 group-hover:opacity-100 transition-opacity duration-500 bg-gradient-to-r from-[var(--color-magenta)]/10 to-[var(--color-blue)]/10 rounded-lg blur-md"></span>
            </a>
            
            <div className="mt-4 text-[var(--color-comment)]/60 text-sm tracking-wide">
              <span className="flex items-center justify-center gap-2">
                <svg className="w-4 h-4 text-[var(--color-cyan)]" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"></path>
                </svg>
                <span>Limited beta spots available — Join now to secure early access</span>
              </span>
            </div>
          </div>
          
          {/* Waitlist form - Inline version */}
          <div 
            ref={formRef}
            className={`mt-8 bg-[var(--color-backgroundDarker)]/95 backdrop-blur-xl rounded-xl border border-[var(--color-selection)]/30 shadow-[0_15px_60px_rgba(0,0,0,0.4),0_0_15px_var(--color-magenta)/10] transform origin-top transition-all duration-500 ease-in-out ${
              showForm ? 'max-h-[600px] opacity-100' : 'max-h-0 opacity-0 overflow-hidden'
            }`}
            style={{
              width: "100%",
              maxWidth: "560px",
              margin: "0 auto"
            }}
          >
            <div className={`transition-all duration-500 ${showForm ? 'opacity-100' : 'opacity-0'}`}>
              {/* Form header */}
              <div className="flex justify-between items-center p-4 border-b border-[var(--color-selection)]/30">
                <h3 className="text-xl font-medium">Join our waitlist</h3>
                <button
                  onClick={toggleForm}
                  className="p-2 rounded-full hover:bg-[var(--color-selection)]/30 transition-colors"
                  aria-label="Close form"
                >
                  <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M18 6L6 18M6 6L18 18" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                  </svg>
                </button>
              </div>
              
              <div className="px-4 py-3 bg-[var(--color-selection)]/10">
                <p className="text-sm text-[var(--color-comment)] text-center">
                  Be among the first to experience ARCHII's personalized therapeutic approach
                </p>
              </div>
              
              {/* Airtable Embed */}
              <div className="h-[450px] overflow-hidden rounded-b-xl">
                <iframe 
                  className="w-full h-[533px] bg-transparent" 
                  src="https://airtable.com/embed/app4R7YLW3rB68X6E/pagkPgY1d5nWZrwea/form"
                  frameBorder="0"
                  onLoad={handleIframeLoad}
                  onError={handleIframeError}
                  style={{background: 'transparent'}}
                  allow="fullscreen"
                  referrerPolicy="origin"
                  sandbox="allow-scripts allow-same-origin allow-forms allow-popups"
                  title="ARCHII Waitlist Form"
                ></iframe>
              </div>
              
              {/* Loading state */}
              <div 
                className={`absolute inset-0 flex items-center justify-center bg-[var(--color-backgroundDarker)]/90 backdrop-blur-lg rounded-xl transition-opacity duration-300 ${
                  formLoading ? 'opacity-100' : 'opacity-0 pointer-events-none'
                }`}
              >
                <div className="text-center">
                  <div className="w-8 h-8 border-2 border-[var(--color-magenta)] border-t-transparent rounded-full animate-spin mx-auto mb-3"></div>
                  <p className="mb-4">Loading form...</p>
                  <p className="text-sm opacity-80">
                    If the form doesn't load, <a 
                      href="https://airtable.com/app4R7YLW3rB68X6E/pagkPgY1d5nWZrwea/form" 
                      target="_blank"
                      rel="noopener noreferrer"
                      className="underline hover:text-[var(--color-cyan)] transition-colors"
                    >
                      click here to open directly
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      {/* Transition to footer */}
      <div className="absolute bottom-0 left-0 w-full h-[25%] bg-gradient-to-b from-transparent to-[var(--color-backgroundDarker)] pointer-events-none"></div>
      
      {/* CTA-specific animations */}
      <style jsx>{`
        @keyframes dash-flow {
          0% { stroke-dashoffset: 10; }
          100% { stroke-dashoffset: 0; }
        }
        
        .animate-dash-flow {
          animation: dash-flow 1s linear infinite;
        }
      `}</style>
    </section>
  );
}

// Footer
function Footer({ colors, colorProgress }) {
  // Footer structure from the second document
  const footerLinks = [
    {
      heading: "Resources",
      links: [
        {label: "Coming Soon", url: "#", disabled: true}
      ]
    },
    {
      heading: "Company",
      links: [
        {label: "Coming Soon", url: "#", disabled: true}
      ]
    },
    {
      heading: "Legal",
      links: [
        {label: "Coming Soon", url: "#", disabled: true}
      ]
    }
  ];
  
  const socialLinks = [
    {label: "TikTok", url: "#", icon: "tiktok"},
    {label: "Instagram", url: "#", icon: "instagram"}
  ];
  
  // Render social icon
  const renderSocialIcon = (name) => {
    switch(name) {
      case 'tiktok':
        return (
          <svg className="w-4 h-4" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
            <path d="M9 12a3 3 0 1 0 0 6 3 3 0 0 0 0-6z"/>
            <path d="M16 8v8a5 5 0 0 1-5 5v0a5 5 0 0 1-5-5v0-8"/>
            <path d="M22 8V6a4 4 0 0 0-4-4v0a4 4 0 0 0-4 4v0 10"/>
          </svg>
        );
      case 'instagram':
        return (
          <svg className="w-4 h-4" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
            <rect x="2" y="2" width="20" height="20" rx="5" ry="5"></rect>
            <path d="M16 11.37A4 4 0 1112.63 8 4 4 0 0116 11.37z"></path>
            <line x1="17.5" y1="6.5" x2="17.51" y2="6.5"></line>
          </svg>
        );
      default:
        return null;
    }
  };
  
  return (
    <footer className="relative z-60 py-12 md:py-16 lg:py-20 px-6 md:px-8 lg:px-16 bg-[var(--color-backgroundDarker)] scroll-section">
      {/* Subtle footer accents */}
      <div className="absolute inset-0 opacity-10 overflow-hidden">
        <div 
          className="absolute w-[300px] h-[300px] rounded-full blur-[120px]"
          style={{
            top: '10%',
            right: '5%',
            background: 'linear-gradient(135deg, var(--color-magenta), transparent)',
            opacity: '0.04'
          }}
        ></div>
        <div 
          className="absolute w-[300px] h-[300px] rounded-full blur-[120px]"
          style={{
            bottom: '5%',
            left: '10%',
            background: 'linear-gradient(135deg, var(--color-blue), transparent)',
            opacity: '0.03'
          }}
        ></div>
      </div>
      
      <div className="max-w-[1400px] mx-auto flex flex-col md:flex-row justify-between flex-wrap gap-8 md:gap-12 relative z-10">
        <div className="flex-1 min-w-[250px] md:min-w-[300px] story-element">
          {/* Logo */}
          <a href="#hero" className="inline-block">
            <div className="font-sans text-3xl md:text-4xl font-bold mb-5 md:mb-6 tracking-tighter relative group">
              <span className="bg-clip-text text-transparent bg-gradient-to-r from-[var(--color-mmagenta)] to-[var(--color-mblue)]">ARCHII</span>
              <div className="absolute bottom-[-2px] left-0 w-[40px] h-px bg-gradient-to-r from-[var(--color-magenta)] to-[var(--color-blue)] transition-all duration-500 ease-out group-hover:w-full"></div>
            </div>
          </a>
          
          {/* Mission statement */}
          <p className="text-[var(--color-comment)] leading-7 mb-6 md:mb-8 max-w-[400px]">
            We're building therapy that works the way your mind does — tracking real emotions, matching your personality, and understanding your life context for truly personalized mental health support.
          </p>
        </div>
        
        <div className="flex flex-wrap gap-8 md:gap-12 lg:gap-16">
          {footerLinks.map((column, colIndex) => (
            <div key={colIndex} className="min-w-[150px] story-element" style={{"--delay": `${0.2 + colIndex * 0.1}s`}}>
              <h3 className="uppercase text-sm tracking-wider mb-4 md:mb-5 text-[var(--color-foreground)] relative inline-block group">
                {column.heading}
                <div 
                  className="absolute bottom-[-3px] left-0 w-[20px] h-px transition-all duration-600 ease-out group-hover:w-full"
                  style={{
                    background: colIndex === 0 
                      ? 'var(--color-magenta)/40'
                      : colIndex === 1
                        ? 'var(--color-blue)/40'
                        : 'var(--color-cyan)/40'
                  }}
                ></div>
              </h3>
              <ul>
                {column.links.map((link, linkIndex) => (
                  <li key={linkIndex} className="mb-3 md:mb-4">
                    <a 
                      href={link.url} 
                      className={`text-[var(--color-comment)] ${link.disabled ? 'cursor-default' : 'hover:text-[var(--color-foreground)]'} relative group text-sm md:text-base`}
                    >
                      {link.label}
                      {!link.disabled && (
                        <span className="absolute bottom-[-2px] left-0 w-0 h-px bg-[var(--color-foreground)]/20 transition-all duration-500 ease-out group-hover:w-full"></span>
                      )}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
      
      <div className="max-w-[1400px] mx-auto mt-12 md:mt-14 pt-8 md:pt-10 border-t border-[var(--color-selection)]/5 text-[var(--color-comment)] flex flex-col md:flex-row justify-between items-center md:items-start gap-4 relative z-10 story-element" style={{"--delay": "0.6s"}}>
        <p className="text-sm md:text-base">&copy; 2025 ARCHII. All rights reserved.</p>
        
        <div className="flex gap-4 md:gap-6 flex-wrap justify-center">
          {socialLinks.map((link, index) => (
            <a 
              key={index}
              href={link.url} 
              className="text-[var(--color-comment)] hover:text-[var(--color-foreground)] relative group text-sm md:text-base flex items-center gap-2"
              data-cursor-text={link.label}
            >
              {renderSocialIcon(link.icon)}
              <span>{link.label}</span>
              <span className="absolute bottom-[-2px] left-0 w-0 h-px bg-[var(--color-foreground)]/20 transition-all duration-500 ease-out group-hover:w-full"></span>
            </a>
          ))}
        </div>
      </div>
    </footer>
  );
}

export default LandingPrem;