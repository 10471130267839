import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Landing from './screens/landing/landing4/Landing7';
// import Landing21 from './screens/landing/Landing2';
// import Landing31 from './screens/landing/Landing3';
// import Landing41 from './screens/landing/Landing4';

// import PostDetail from './screens/PostDetail';
// import CategoryScreen from './screens/CategoryScreen';

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<Landing />} />
          {/* <Route path="/post/:id" element={<PostDetail />} />
          <Route path="/category/:category" element={<CategoryScreen />} /> */}
        </Routes>
      </div>
    </Router>
  );
}

export default App;
